import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class UserActions {
  static SET_USER = 'SET_USER';

  static setUser(user: any): AnyAction {
    return {
      type: UserActions.SET_USER,
      payload: user,
    };
  }
}
