import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
  @Input() public data: any;

  public apiBase = 'https://maps.googleapis.com/maps/api/staticmap';

  public mapType = 'roadmap'; // 'hybrid';

  public width = 640; // valori ininfluenti nelle static
  public height = 640;
  public zoom = 15; // 12

  ngOnInit() {}

  get url(): string {
    const pos: any =
      typeof this.data !== 'undefined' && this.data !== null
        ? JSON.parse(this.data)
        : {
            lat: 44.340675,
            lng: 8.653654,
          };

    const lat = pos.lat;

    const lng = pos.lng;

    // const markers = `|${lat},${lng}`;
    const center = `|${lat},${lng}`;

    const key = 'AIzaSyC3sLd_qqt-uWWri-2crweCcdmkei8HJNQ'; // todo: save asset

    const url = `${this.apiBase}?zoom=${this.zoom}&size=${this.width}x${this.height}&maptype=${this.mapType}&center=${center}&key=${key}`;

    return url;
  }
  zoomPlus() {
    if (this.zoom < 20) {
      this.zoom++;
    }
  }

  zoomMinus() {
    if (this.zoom > 1) {
      this.zoom--;
    }
  }

  changeType() {
    this.mapType = this.mapType === 'roadmap' ? 'hybrid' : 'roadmap';
  }
}
