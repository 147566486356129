<app-header *ngIf="toggle('pages.general.header_info')"></app-header>
<router-outlet></router-outlet>

<app-articles-list
  *ngIf="toggle('pages.general.articles_list')"
></app-articles-list>

<app-google-review-list
  *ngIf="toggle('pages.general.review_list')"
></app-google-review-list>

<app-footer></app-footer>
<app-cookie></app-cookie>
