<div class="preset-urlwrp">
  <h3>{{ 'DYNAMIC_URLS.TITLE' | staticContent }}</h3>

  <div class="preset-url">
    <ng-container *ngFor="let list of presetUrlGroups | async; let i = index">
      <a
        class="preset-url__list__title"
        [ngClass]="{ 'noBorder': y === 0 }"
        *ngFor="let url of list.urls; let y = index"
        href="/case-in-vendita/{{ url.seoUrl }}"
        title="{{ url.title }}"
        [appNgAnalyticsSendEvent]="[
          'Click',
          'footer[route=\'' + route.children[0]?.routeConfig.path + '\'].seoFilter.' + i
        ]"
        >{{ url.hrefText }}</a
      >
    </ng-container>
  </div>
</div>
