import { Reducer } from 'redux';

const initialState: any[] = [];

export const propertiesReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_PROPERTIES':
      return action.payload;

    case 'DELETE_PROPERTY':
      return [
        ...state.slice(0, action.payload),
        ...state.slice(action.payload + 1),
      ];
  }

  return state;
};
