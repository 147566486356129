import { Reducer } from 'redux';

const initialState = {};

export const locationReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        ...action.payload,
      };

    case 'SET_LOCATION_CITIES_AND_AREAS':
      return {
        ...state,
        citiesAndAreas: action.payload,
      };
  }

  return state;
};
