import { Reducer } from 'redux';

const initialState = {
  footerUrls: [],
  landingPage: null,
};

export const presetUrlsReducer: Reducer<any> = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case 'SET_PRESET_URL_GLOBAL':
      return {
        ...action.payload,
      };

    case 'SET_FOOTER_URL':
      return {
        ...state,
        footerUrls: [...action.payload],
      };

    case 'SET_LANDING_PAGE':
      return {
        ...state,
        landingPage: { ...action.payload },
      };

    case 'SET_LANDING_PAGE_AREAS':
      return {
        ...state,
        landingPageAreas: [...action.payload],
      };
  }
  return state;
};
