import { Injectable } from '@angular/core';

@Injectable()
export class LazyImageObserverService {
  private observer!: IntersectionObserver;

  constructor() {
    if (typeof window !== 'undefined') {
      this.init();
    }
  }

  public add(el: any) {
    this.observer.observe(el);
  }

  public remove(el: any) {
    this.observer.unobserve(el);
  }

  private init() {
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;

          const dataSrc = lazyImage.getAttribute('data-src');

          const dataSrcSet = lazyImage.getAttribute('data-srcset');

          lazyImage.setAttribute('src', dataSrc || '');
          lazyImage.setAttribute('srcset', dataSrcSet || '');
        }
      });
    });
  }
}
