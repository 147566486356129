import { combineReducers, Reducer } from 'redux';
import { IAppState } from '../../interface/app-state.interface';
import { propertiesReducer } from './properties/properties.reducers';
import { propertyReducer } from './property/property.reducers';
import { pageReducer } from './page/page.reducers';
import { configReducer } from './config/config.reducers';
import { presetUrlsReducer } from './preseturl/preset-url.reducers';
import { citiesReducer } from './cities/cities.reducers';
import { articleReducer } from './article/article.reducers';
import { infoReducer } from './info/info.reducers';
import { locationReducer } from './location/location.reducers';
import { contentReducer } from './content/content.reducers';
import { clientReducer } from './client/client.reducers';
import { userReducer } from './user/user.reducers';

// const serverAppState = this.transferState.get('APP_STATE');
// export const INITIAL_STATE: IAppState = (serverAppState)
//   ? serverAppState
//   : {properties: []};prop

export const INITIAL_STATE: IAppState = {
  properties: [],
  property: null,
  page: null,
  config: undefined, // force using reducer's initial state.
  presetUrls: null,
  cities: [],
  article: null,
  info: null,
  location: null,
  content: null,
  client: null,
  user: null,
};

export const rootReducer: Reducer<IAppState> = combineReducers({
  properties: propertiesReducer,
  property: propertyReducer,
  page: pageReducer,
  config: configReducer,
  presetUrls: presetUrlsReducer,
  cities: citiesReducer,
  article: articleReducer,
  info: infoReducer,
  location: locationReducer,
  content: contentReducer,
  client: clientReducer,
  user: userReducer,
});
