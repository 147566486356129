import { Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { IDomainsConfig } from './interface/domains.config.interface';
import {
  IDomainContent,
  IDomainContentItem,
  IDomainRobots,
} from './interface/domains.content.interface';

// todo: use injection !!!

const domainsConfig: IDomainsConfig = require(`json-loader!yaml-loader!./../config/domains.config.yml`);

const env = 'production';

export const configDomainMap: IDomainContent = domainsConfig[env].reduce(
  (acc, currBusiness) => {
    const domainConf = require(`json-loader!yaml-loader!../content/${currBusiness.name}.yml`);

    const data = currBusiness.domains.reduce(
      (acc2, currDomain) => ({
        ...acc2,
        [currDomain]: domainConf,
      }),
      {}
    );
    return { ...acc, ...data };
  },
  {}
);

export const configDomainRobots: IDomainRobots = domainsConfig[env].reduce(
  (acc, currBusiness) => {
    const data = currBusiness.domains.reduce(
      (acc2, currDomain) => ({
        ...acc2,
        [currDomain]: {
          allowDomains: currBusiness.robots.allowDomains,
          // allowDomains: currBusiness.robots.allowDomains.indexOf(currDomain) > -1
        },
      }),
      {}
    );
    return { ...acc, ...data };
  },
  {}
);

// todo: separate content from env cfg
export let domainConfig: IDomainContentItem | null = null;

export function configFactory(injector: Injector) {
  const domain =
    typeof window === 'undefined'
      ? stripSubdomain(injector.get(REQUEST).headers.host || '')
      : stripSubdomain(window.location.hostname);

  domainConfig = configDomainMap[domain];

  return domainConfig;
}

export function stripSubdomain(s: string): string {
  return s
    .split('.')
    .reduce((acc: any, curr, i, arr) => {
      if (i === arr.length - 1 && acc.length === 2) {
        acc.splice(0, 1);
      }
      return [...acc, curr];
    }, [])
    .join('.');
}

export function configFactoryServer(domain: string): IDomainContentItem {
  return configDomainMap[domain];
}
