import { Reducer } from 'redux';

const initialState: string[] = [];

export const citiesReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_CITIES':
      return action.payload;
  }

  return state;
};
