import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class CitiesActions {
  static SET_CITIES = 'SET_CITIES';

  static setCities(cities: any): AnyAction {
    return {
      type: CitiesActions.SET_CITIES,
      payload: cities,
    };
  }
}
