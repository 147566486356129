import { NgRedux, select } from '@angular-redux/store';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import * as Cookies from 'es-cookie';
import { Observable } from 'rxjs';
import { StaticContentPipe } from 'src/app/pipe/static-content.pipe';
import { IAppState } from '../../interface/app-state.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @select(['content']) public content!: Observable<any>;
  @select(['user']) public user!: Observable<any>;

  public constructor(
    protected router: Router,
    protected store: NgRedux<IAppState>
  ) {}

  public isActive(uri: string) {
    return uri === this.router.url;
  }

  public toggle(key: string) {
    return StaticContentPipe.getFeatures(key);
  }

  public logoutLabel() {
    let userName;
    this.user.subscribe((user) => (userName = user?.name));

    return userName ? `logout (${userName})` : 'logout';
  }

  public logout() {
    Cookies.remove('hr_tk');
  }

  public render(key: string) {
    const fnc = key?.split('::');

    return fnc?.[0] === 'fnc' ? (this as any)[fnc[1]]() : key;
  }
}
