import {
  Component,
  Optional,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { server } from '../../decorator/server.decorator';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { client } from '../../decorator/client.decorator';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { ArticleActions } from '../../module/store/article/article.action';
import { AssetLib } from '../../lib/assets.lib';
import { IArticle } from '../../interface/article.interface';
import { IPageMeta } from '../../interface/pagemeta';
import { MetaService } from '../../meta.service';
import { StaticContentPipe } from '../../pipe/static-content.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleComponent extends AssetLib {
  @select(['article', 'article']) public article!: Observable<IArticle>;
  @select(['config', 'noMatchFound']) public noMatchFound!: Observable<boolean>;

  public autoUnsubscribe = new AutoUnsubscribe();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DataService,
    protected store: NgRedux<IAppState>,
    private meta: MetaService,

    @Optional() @Inject(RESPONSE) private response: any
  ) {
    super();
    this.checkLocationClient();

    const subscription = this.route.params.subscribe((params: Params) => {
      const seoUrl = params['seoUrl'];
      return this.getArticle(seoUrl);
    });

    this.autoUnsubscribe.add(subscription);
  }

  @client
  public checkLocationClient() {
    this.noMatchFound.subscribe((v) => {
      if (v === true) {
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    });
  }

  @server
  public getArticle(seoUrl: string): Subscription {
    const subscription = this.service.getArticle(seoUrl).subscribe(
      (data: any) => {
        if (data.data.length === 0) {
          this.router.navigate(['/404']);
          return;
        }

        const article = data.data[0];

        this.store.dispatch(ArticleActions.setArticle(article));

        const metaObj: IPageMeta = {
          author: StaticContentPipe.getEnv('paths.baseUrl'),
          keywords: article.subtitle,
          description: article.metaDescription,
          siteName: StaticContentPipe.getEnv('paths.baseUrl'),
          title: article.metaTitle,
          image:
            StaticContentPipe.getEnv('paths.baseUrl') +
            this.getImgPath(article.coverImage, 'w1500'),
          url: StaticContentPipe.getEnv('paths.baseUrl') + this.router.url,
          // amphtml:
          //   StaticContentPipe.getEnv('paths.baseUrl') +
          //   '/news/amp/' +
          //   article.seoUrl
        };

        this.meta.setMeta(metaObj);
      },
      (err) => console.log(err)
    );

    this.autoUnsubscribe.add(subscription);
    return subscription;
  }
}
