import { NgRedux, select } from '@angular-redux/store';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { uniq } from 'ramda';
import { Observable } from 'rxjs';
import { DataService } from '../../data.service';
import { server } from '../../decorator/server.decorator';
import { IAppState } from '../../interface/app-state.interface';
import { IPresetUrlGroup } from '../../interface/preset-url-group.interface';
import { IPresetUrl } from '../../interface/preset-url.interface';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { PresetUrlActions } from '../../module/store/preseturl/preset-url.action';

@Component({
  selector: 'app-dynamic-urls',
  templateUrl: './dynamic-urls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicUrlsComponent implements OnInit, OnDestroy {
  @select(['presetUrls', 'footerUrls']) public presetUrlGroups!: Observable<
    IPresetUrlGroup[]
  >;

  public autoUnsubscribe = new AutoUnsubscribe();

  constructor(
    public route: ActivatedRoute,
    private service: DataService,
    protected store: NgRedux<IAppState>
  ) {}

  ngOnInit() {
    this.getPresetUrl();
  }

  ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  @server
  public getPresetUrl() {
    const subscription = this.service.getPresetUrlsList().subscribe(
      (data) => {
        data = data.data.map((r: any) => ({
          ...r,
          category: r.data.categoria,
        }));

        const presetUrlGroups = this.presetUrlDataToGroup(data, 'category');

        this.store.dispatch(PresetUrlActions.setFooterUrl(presetUrlGroups));
      },
      (err) => console.log(err)
    );

    this.autoUnsubscribe.add(subscription);
  }

  public presetUrlDataToGroup(
    data: IPresetUrl[],
    groupByKeyName: string
  ): IPresetUrlGroup[] {
    data = data.map((pu: IPresetUrl) => ({
      ...pu,
      groupName: pu.data.city, // todo: iterative assignment (nested keys).
    }));

    groupByKeyName = 'groupName';

    const groups: string[] = data.map(
      (p: IPresetUrl) => (p as any)[groupByKeyName]
    );

    const groupsUniq = uniq(groups);

    const urlGroups = groupsUniq.map((groupName: string) => {
      return {
        groupKeyName: groupName,
        urls: data.filter(
          (pu: IPresetUrl) => (pu as any)[groupByKeyName] === groupName
        ),
      };
    });

    return urlGroups;
  }
}
