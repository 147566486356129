import { NgRedux, select } from '@angular-redux/store';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  Optional,
} from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { AnyAction } from 'redux';
import { Observable } from 'rxjs';
import Cookies from 'universal-cookie';
import { configFactory } from './config.service';
import { client } from './decorator/client.decorator';
import { IAppState } from './interface/app-state.interface';
import { tokenToUser } from './lib/token.lib';
import { AnalyticsGaService } from './module/ng-analytics/service/service/analytics-ga.service';
import { ArticleActions } from './module/store/article/article.action';
import { UserState } from './module/store/user/user.reducers';
import { CitiesActions } from './module/store/cities/cities.actions';
import { ClientActions } from './module/store/client/client.action';
import { ConfigActions } from './module/store/config/config.action';
import { InfoActions } from './module/store/info/info.action';
import { LocationActions } from './module/store/location/location.actions';
import { PageActions } from './module/store/page/page.action';
import { PresetUrlActions } from './module/store/preseturl/preset-url.action';
import { PropertiesActions } from './module/store/properties/properties.actions';
import { PropertyActions } from './module/store/property/property.action';
import { StaticContentPipe } from './pipe/static-content.pipe';
import { UserActions } from './module/store/user/user.actions';

const REDUX_STATE_KEY = makeStateKey<IAppState>('state');
declare const iga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.sass'],
  // encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  @select() public readonly state!: Observable<IAppState>;
  @select(['config', 'noScroll'])
  public readonly noScroll!: Observable<boolean>;
  title = 'app universal';

  public constructor(
    @Optional() @Inject(REQUEST) private request: any,
    private store: NgRedux<IAppState>,
    private transferState: TransferState,
    private gaService: AnalyticsGaService,
    public injector: Injector
  ) {
    const domainConfig = configFactory(this.injector);

    if (typeof window === 'undefined') {
      this.store.dispatch(ClientActions.set(domainConfig.env.client));

      const token = new Cookies(this.request?.headers?.cookie).get('hr_tk');
      // console.log('token', token);

      if (token && typeof token !== 'undefined') {
        const user: UserState | undefined = tokenToUser(token);
        this.store.dispatch(UserActions.setUser(user));
      }

      this.store.subscribe(() => {
        this.transferState.set<IAppState>(
          REDUX_STATE_KEY,
          this.store.getState()
        );
      });
    } else {
      const state = this.transferState.get<IAppState>(
        REDUX_STATE_KEY,
        null as any
      );

      this.storeSet(state, PropertiesActions.setProperties, 'properties', []);
      this.storeSet(state, PropertyActions.setProperty, 'property', []);
      this.storeSet(state, PageActions.setPage, 'page', null);
      this.storeSet(state, ArticleActions.setGlobal, 'article', null);
      this.storeSet(state, ConfigActions.setConfig, 'config', {
        noMatchFound: false,
      });
      this.storeSet(state, PresetUrlActions.setPresetUrls, 'presetUrls', null);
      this.storeSet(state, CitiesActions.setCities, 'cities', []);
      this.storeSet(state, InfoActions.setInfo, 'info', []);
      this.storeSet(state, LocationActions.setLocation, 'location', []);
      this.storeSet(state, UserActions.setUser, 'user', []);

      // =============================================================

      // todo: wrong obj
      const clientState = state && state.client ? state.client : {};
      this.store.dispatch(ClientActions.set(clientState));
    }
  }

  public toggle(key: string) {
    return StaticContentPipe.getFeatures(key);
  }

  public storeSet = (
    state: any,
    action: (payload: any) => AnyAction,
    key: string,
    defaultValue: any
  ) => {
    const value = state && state[key] ? state[key] : defaultValue;
    this.store.dispatch(action(value));
  };

  @client
  public initGA() {
    // if (this.cookieAccepted) {
    //   iga();
    // }
    this.gaService.send('pageview');
  }
}
