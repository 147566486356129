import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class PresetUrlActions {
  static SET_PRESET_URL_GLOBAL = 'SET_PRESET_URL_GLOBAL';
  static SET_FOOTER_URL = 'SET_FOOTER_URL';
  static SET_LANDING_PAGE = 'SET_LANDING_PAGE';
  static SET_LANDING_PAGE_AREAS = 'SET_LANDING_PAGE_AREAS';

  static setPresetUrls(presetUrlObj: any): AnyAction {
    return {
      type: PresetUrlActions.SET_PRESET_URL_GLOBAL,
      payload: presetUrlObj,
    };
  }

  static setFooterUrl(presetUrlsGroups: any[]): AnyAction {
    return {
      type: PresetUrlActions.SET_FOOTER_URL,
      payload: presetUrlsGroups,
    };
  }

  static setLandingPage(presetUrl: any): AnyAction {
    return {
      type: PresetUrlActions.SET_LANDING_PAGE,
      payload: presetUrl,
    };
  }

  static setLandingPageAreas(presetUrl: any): AnyAction {
    return {
      type: PresetUrlActions.SET_LANDING_PAGE_AREAS,
      payload: presetUrl,
    };
  }
}
