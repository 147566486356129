import { NgRedux, select } from '@angular-redux/store';
import {
  ChangeDetectionStrategy,
  // Renderer2,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { DataService } from '../../data.service';
import { client } from '../../decorator/client.decorator';
import { server } from '../../decorator/server.decorator';
import { IAppState } from '../../interface/app-state.interface';
import { IPageMeta } from '../../interface/pagemeta';
import { IProperty } from '../../interface/property.interface';
import { AssetLib } from '../../lib/assets.lib';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { env } from '../../lib/config.lib';
import { MetaService } from '../../meta.service';
import { ConfigActions } from '../../module/store/config/config.action';
import { PropertyActions } from '../../module/store/property/property.action';
import { StaticContentPipe } from '../../pipe/static-content.pipe';
import { PrintProperty } from '../../print';

// import { RESPONSE } from '@nguniversal/express-engine/tokens';
// import { DOCUMENT } from '@angular/common';

export interface IHFeature {
  label: string;
  properties: string[];
  icon: string;
  measure: string;
  template: string;
  transform?: any;
}

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyComponent extends AssetLib implements OnInit, OnDestroy {
  @select(['property']) public immobile!: Observable<IProperty>;
  @select(['config', 'noMatchFound']) public noMatchFound!: Observable<boolean>;

  public autoUnsubscribe = new AutoUnsubscribe();
  public visibility: any = {};
  public highlightedFeatures: IHFeature[] = [
    {
      label: 'superficie',
      properties: ['surfaceSqm'],
      icon: 'icon-icons_surface',
      measure: '<span class="lower">m<sup>2</sup></span>',
      template: '$1',
    },
    {
      label: 'piano ',
      properties: ['floorNumber', 'floorTotal'],
      icon: 'icon-icons_floors',
      measure: '',
      template: '$1 / $2',
      transform: {
        templateValue: '$1',
        equals: 0,
        replacementValue: 'terra',
      },
      // transformers: { //todo: better implementation of the above
      //   conditions: [],
      //   rules: [],
      // },
    },
    {
      label: 'camere',
      properties: ['bedroomsCount'],
      icon: 'icon-icons_bedroom',
      measure: '',
      template: '$1',
    },
    {
      label: 'bagni',
      properties: ['bathroomsCount'],
      icon: 'icon-icons_bathroom',
      measure: '',
      template: '$1',
    },
  ];

  public galleryFullScreen = false;
  public fsLabel = 'Ingrandisci la foto';

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private service: DataService,
    private ref: ChangeDetectorRef,
    private meta: MetaService,
    public store: NgRedux<IAppState> // private renderer: Renderer2, // @Optional() @Inject(RESPONSE) private response: any // @Inject(DOCUMENT) private document: Document
  ) {
    super();

    this.checkLocationClient();

    this.route.params.subscribe((params) => {
      const seoUrl = params['seoUrl'];

      return this.getDettaglio(seoUrl);
    });

    this.visibility.contactForm = false;
  }

  ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  doPrint() {
    const subscription = this.immobile.subscribe(
      (data) => {
        PrintProperty.print(data, env.paths.clientBase);
      },
      (err) => console.log('err', err)
    );

    this.autoUnsubscribe.add(subscription);
  }

  @server
  public getDettaglio(seoUrl: string): Subscription | null {
    const subscription = this.service.getDettaglio(seoUrl).subscribe(
      (data: any) => {
        if (data.data.length === 0) {
          this.router.navigate(['/404']);
          return;
        }

        const immobile: IProperty = data.data[0] || null;

        this.store.dispatch(PropertyActions.setProperty(immobile));

        // const t = StaticContentPipe.getEnv('paths.baseUrl');
        // console.log('333', t);

        this.setMeta();
      },
      (err: any) => {
        console.log(err);
      }
    );

    this.autoUnsubscribe.add(subscription);

    return subscription;
  }

  public get featuresList() {
    const immobile: IProperty | null = this.store.getState().property;

    if (immobile === null) {
      return [];
    }

    const meta = [
      { key: 'featureBalcony', label: 'balcone' },
      { key: 'featureBox', label: 'garage' },
      { key: 'featureCarPark', label: 'posto auto' },
      { key: 'featureLift', label: 'ascensore' },
      { key: 'featureVeranda', label: 'veranda' },
    ];

    return meta.map((item: any): any => {
      return {
        ...item,
        value: (immobile as any)[item.key],
      };
    });
  }

  // todo: move in separated component

  public renderFeatureValue(feature: IHFeature): string {
    const data = feature.properties.map((propertyName) =>
      this.getHFeatureByName(propertyName)
    );

    return feature.template.replace(
      /\$([0-9])/g,
      (
        substring: string,
        index: number // value
        // pos: number,
      ) => {
        if (typeof feature.transform !== 'undefined') {
          if (
            substring === feature.transform.templateValue &&
            data[index - 1] === 0
          ) {
            data[index - 1] = feature.transform.replacementValue;
          }
        }

        return data[index - 1];
      }
    );
  }

  public getHFeatureByName(name: string) {
    const property = this.store.getState().property;

    return typeof property !== 'undefined' &&
      property !== null &&
      typeof (property as any)[name] !== 'undefined' &&
      (property as any)[name] !== null
      ? (property as any)[name]
      : '-';
  }

  public getSqm(name: string) {
    const property = this.store.getState().property;

    return property !== null && (property as any)[name]
      ? (property as any)[name] +
          '<span class="measure lower">m<sup>2</sup></span>'
      : '-';
  }

  public ngOnInit() {
    const info = this.route.snapshot.queryParamMap.get('info');

    if (info) {
      this.formVisibilityChange(true);
    }
  }

  // public getImgPathBackground = (name, size) => AssetLib.getImgPathBackground(name, size);
  // public getImgPath = (name, size) => AssetLib.getImgPath(name, size);

  // todo: move in utils / pipe
  // public strToNumber = (value: string): number => Number(value.split(',').join('.'))

  @client
  public checkLocationClient() {
    // this.noMatchFound.valueOf()
    // console.log('=======', this.noMatchFound);
    const subscription = this.noMatchFound.subscribe((v) => {
      if (v === true) {
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    });

    this.autoUnsubscribe.add(subscription);
  }

  public showContactForm() {
    this.formVisibilityChange(true);
  }

  public formVisibilityChange(visible: boolean) {
    this.visibility.contactForm = visible;

    this.ref.markForCheck();

    const htmlSel = document.getElementsByTagName('html')[0];
    const bodySel = document.getElementsByTagName('body')[0];

    const className = 'no-scroll';

    if (visible) {
      htmlSel.classList.add(className);
      bodySel.classList.add(className);
    } else {
      htmlSel.classList.remove(className);
      bodySel.classList.remove(className);
    }
  }

  public toggleGalleryFullScreen() {
    this.store.dispatch(ConfigActions.galleryToggleFullScreen());

    const fullScreen = this.store.getState().config?.galleryFullScreen;

    fullScreen
      ? (this.fsLabel = 'Riduci')
      : (this.fsLabel = 'Ingrandisci la foto');
  }

  // spese condominiali
  public getServiceCharge(): string {
    const state: IAppState = this.store.getState();

    if (
      typeof state === 'undefined' ||
      state === null ||
      state.property === null
    ) {
      return '-';
    }

    const amount = state.property.propertyServiceCharge;

    const yearly = state.property.propertyServiceChargeYearly;

    return amount !== null
      ? yearly !== true
        ? amount + ' mensili'
        : amount + ' annuali'
      : '-';
  }

  public setMeta(): void {
    const immobile: IProperty | null = this.store.getState().property;

    if (!immobile) {
      // TODO: warn
      return;
    }

    const metaObj: IPageMeta = {
      author: StaticContentPipe.getEnv('paths.baseUrl'),
      keywords: immobile.metaDescription,
      description: immobile.description,
      siteName: StaticContentPipe.getEnv('paths.baseUrl'),
      title: immobile.metaTitle,
      image:
        StaticContentPipe.getEnv('paths.baseUrl') +
        this.getImgPath(immobile.assets[0].fileName, 'w1500'),
      url: StaticContentPipe.getEnv('paths.baseUrl') + this.router.url,
      amphtml:
        StaticContentPipe.getEnv('paths.baseUrl') +
        '/immobili-in-vendita-a-savona/amp/' +
        immobile.seoUrl,
    };

    this.meta.setMeta(metaObj);
  }

  public getContent(key: string): string {
    return StaticContentPipe.getContent(key);
  }

  public toggle(key: string): string {
    return StaticContentPipe.getFeatures(key);
  }

  public capitalize(s: string): string {
    return s[0].toUpperCase() + s.slice(1);
  }
}
