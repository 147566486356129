import { Pipe, PipeTransform } from '@angular/core';
import { Obj } from '../lib/obj.lib';

@Pipe({
  name: 'collectionFilter',
})
export class CollectionFilterPipe implements PipeTransform {
  transform(
    collection: any[] | null,
    key: string,
    operator?: string,
    value?: any
  ): any[] | null {
    const props = key.split('.');

    if (!collection) {
      return collection;
    }

    collection = collection.filter((record: any) => {
      const v = Obj.recursiveProps(record, props);

      switch (operator) {
        case '>':
          return v > value;

        case '<':
          return v > value;

        case '===':
          return v === value;

        default:
          return !!v;
      }
    });

    return collection;
  }
}
