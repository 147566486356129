import { NgRedux, select } from '@angular-redux/store';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../../data.service';
import { server } from '../../decorator/server.decorator';
import { IAppState } from '../../interface/app-state.interface';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { Obj } from '../../lib/obj.lib';
import { CitiesActions } from '../../module/store/cities/cities.actions';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent implements OnInit, OnDestroy {
  @select(['cities']) public cities!: Observable<string[]>;

  public myControl = new UntypedFormControl();
  public options: string[] = [];
  public filteredOptions!: string[];
  public autoUnsubscribe = new AutoUnsubscribe();

  constructor(
    private service: DataService,
    private router: Router,
    protected store: NgRedux<IAppState>
  ) {
    this.getCities();
  }

  @server
  private async getCities() {
    const res = await this.service.getCities();

    const filteredOptions = res.data
      .filter((v: string) => v !== null && v !== '')
      .sort();

    this.store.dispatch(CitiesActions.setCities(filteredOptions));
  }

  ngOnInit() {
    const subscriber = this.myControl.valueChanges.subscribe((s: string) => {
      this.filteredOptions = this.filter(s);
    });

    this.autoUnsubscribe.add(subscriber);

    const subscriber2 = this.cities.subscribe(
      (cities) => (this.options = this.filteredOptions = cities)
    );

    this.autoUnsubscribe.add(subscriber2);
  }

  ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option: string) =>
        option !== null &&
        option !== '' &&
        option.toLowerCase().includes(filterValue)
    );
  }

  onSubmit(): void {
    this.router.navigate(['/search'], {
      queryParams: {
        comune: this.myControl.value,
      },
    });
  }

  public getUrl(cities: string[] | null): string {
    const value = this.myControl.value;

    if (value === null || value.length === 0) {
      return '';
    }

    if ((cities || []).indexOf(value) === -1) {
      const params = {
        refId: value,
      };

      return `?${Obj.toUrlParams(Obj.unsetEmpty(params))}`;
    }

    // todo: move in config
    const subPath = 'comune';

    return `/${subPath}/${value}`;
  }
}
