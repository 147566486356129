import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsGaService } from '../service/service/analytics-ga.service';

@Directive({
  selector: '[appNgAnalyticsSendEvent]',
})
export class NgAnalyticsSendDirective {
  constructor(protected gaService: AnalyticsGaService) {}

  @Input() public appNgAnalyticsSendEvent!: any[];

  @HostListener('click', ['$event'])
  clickEvent(/*event*/) {
    this.gaService.send('event', ...this.appNgAnalyticsSendEvent);
    // event.preventDefault();
    // event.stopPropagation();
    // console.log('Click from Host Element!');
  }
}
