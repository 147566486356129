import { env } from './config.lib';
import { IAsset } from '../interface/asset.interface';

export class AssetLib {
  public getImgPathUrl(name: string, size: string): string {
    return `url(${this.getImgPath(name, size)})`;
  }

  public getImgPath(name: string, size: string): string {
    return `${env.paths.assets}/${size}/${name}`;
  }

  public imgDataToAssetsData(
    imgData: any[],
    urlProperty: string = 'fileName'
  ): { opacity: number; url: string; zIndex: number }[] {
    if (typeof imgData === 'undefined' || imgData === null) {
      return [];
    }

    const sortColumn =
      imgData.filter((img: any) => img.sortOrder === null).length > 0
        ? 'id'
        : 'sortOrder';

    const cmp = this.compareByCol(sortColumn);

    const data = imgData.sort(cmp).map((img, i: number) => ({
      loaded: i === 0,
      url: img[urlProperty],
      opacity: i === 0 ? 1 : 0,
      zIndex: imgData.length - i,
    }));

    return data;
  }

  public compareByCol(col: string): (a: any, b: any) => number {
    return (a, b) => a[col] - b[col];
  }

  public getSrcSet(asset: IAsset, sizes: number[]): string {
    return sizes
      .map((size: number) => {
        const path = this.getLoadedImgPath(asset, `w${size}`);

        return path !== '' ? `${path} ${size}w` : null;
      })
      .filter((item) => item !== null)
      .join(', ');
  }

  public getLoadedImgPath(asset: IAsset, resizedPresetName: string): string {
    return asset.loaded ? this.getImgPath(asset.url, resizedPresetName) : '';
  }

  public imgNameToAlt(text: string): string {
    return (
      text
        .split('.')[0]
        .split('-')
        // .splice(-1)
        .join(' ')
    );
  }
}
