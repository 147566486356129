import { Pipe, PipeTransform } from '@angular/core';
import { Obj } from '../lib/obj.lib';
import { domainConfig } from '../config.service';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'staticContent',
})
export class StaticContentPipe implements PipeTransform {
  public static getKey(key: string): any {
    const path = key.split('.');
    const value = Obj.getPathValue(domainConfig, path);

    if (!environment.production && value === null) {
      console.warn(`key '${key}' is null`);
    }

    return value;
  }

  public static getEnv(key: string = '') {
    return this.getKey([`env`, key].filter((v) => v.length > 0).join('.'));
  }

  public static getFeatures(key: string = ''){
    return this.getKey([`features`, key].filter((v) => v.length > 0).join('.'));
  }

  public static getContent(key: string = ''): any {
    // ???
    const k: string = [`content`, key].filter((v) => v.length > 0).join('.');
    return this.getKey(k);
  }

  public transform(key: string): any {
    return StaticContentPipe.getContent(key);
  }
}
