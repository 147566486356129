import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { env } from './lib/config.lib';
import { LazyImageObserverService } from './service/lazy-image-observer.service';
// import html2canvas from '../../node_modules/html2canvas/dist/html2canvas.js';
const blankImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

@Directive({
  selector: '[appLazy]',
})
export class LazyDirective implements AfterContentInit, OnDestroy {
  constructor(
    private el: ElementRef<HTMLElement>,
    private lazyImgObserverService: LazyImageObserverService
  ) {
    if (typeof window !== 'undefined') {
      lazyImgObserverService.add(el.nativeElement);
    }
  }

  @HostBinding('src') public _src!: string;
  @HostBinding('srcset') public _srcset!: string;

  @Input() src = '';
  @Input() srcset = '';

  public ngAfterContentInit() {
    this.el.nativeElement.setAttribute('data-src', this.src);
    this.el.nativeElement.setAttribute('data-srcset', this.srcset);

    // if (typeof document === 'undefined') {}

    if (typeof window === 'undefined' && env.settings.lazyLoadAssets === true) {
      // server
      this._src = this.src;
      this._srcset = blankImg;
    } else if (this.isInViewport(this.el.nativeElement)) {
      // in viewport
      this._src = this.src;
      this._srcset = this.srcset;
    } else {
      // not in viewport
      this._src = this.src;
      this._srcset = blankImg;
    }

    // console.log('bi', {
    //   original: this.src,
    //   modified: this._src,
    //   blank: blankImg
    // });
  }

  private isInViewport(elem: Element) {
    const bounding = elem.getBoundingClientRect();

    const iiw =
      bounding.top >= 0 - bounding.height &&
      bounding.left >= 0 - bounding.width &&
      bounding.bottom - bounding.height <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right - bounding.width <=
        (window.innerWidth || document.documentElement.clientWidth);

    //
    // if (iiw) {
    //   console.log('b', bounding);
    //   console.log(bounding.top, 0 - bounding.height);
    //   console.log(bounding.left, 0 - bounding.width);
    //   console.log(elem);
    //   console.log('v', bounding.bottom - bounding.height, window.innerHeight, document.documentElement.clientHeight);
    //   console.log('h', bounding.right - bounding.width, window.innerWidth, document.documentElement.clientWidth);
    //
    //   // html2canvas(document.querySelector('body')).then(canvas => {
    //   //   document.body.appendChild(canvas);
    //   // });
    // }

    return iiw;
  }

  public ngOnDestroy() {
    if (typeof window !== 'undefined') {
      this.lazyImgObserverService.remove(this.el.nativeElement);
    }
  }

  // private check(entry: IntersectionObserverEntry, force: boolean) {
  //   const isVisible = () => {
  //     const partiallyVisible = entry.isIntersecting || entry.intersectionRatio > 0;
  //     // const completelyVisible = entry.intersectionRatio >= 1;
  //     return partiallyVisible;
  //   };
  //   // const visible = force || !entry || isVisible();
  //   // return { [InViewportMetadata]: { entry }, target: this.elementRef.nativeElement, visible };
  // }
}
