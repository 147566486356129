import { NgModule /*, APP_INITIALIZER, Injector*/ } from '@angular/core';
import {
  BrowserModule,
  // BrowserTransferStateModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgAnalyticsModule } from './module/ng-analytics/ng-analytics.module';
import { StoreModule } from './module/store/store.module';

import { AlertDialogComponent } from './component/alert-dialog/alert-dialog.component';
import { BoxComponent } from './component/box/box.component';
import { ContactFormComponent } from './component/contact-form/contact-form.component';
import { CookieComponent } from './component/cookie/cookie.component';
import { DynamicUrlsComponent } from './component/dynamic-urls/dynamic-urls.component';
import { FeaturesComponent } from './component/features/features.component';
import { FooterComponent } from './component/footer/footer.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './component/home/home.component';
import { MapComponent } from './component/map/map.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { PagesComponent } from './component/pages/pages.component';
import { PreviewComponent } from './component/preview/preview.component';
import { PropertyEnergyEfficiencyComponent } from './component/property-energy-efficiency/property-energy-efficiency.component';
import { PropertySegmentComponent } from './component/property-segment/property-segment.component';
import { PropertyComponent } from './component/property/property.component';
import { RecaptchaComponent } from './component/recaptcha/recaptcha.component';
import { SearchComponent } from './component/search/search.component';
import { ShareComponent } from './component/share/share.component';
import { DecodeUriComponentPipe } from './pipe/decode-uri-component.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';

import { ArticleComponent } from './component/article/article.component';
import { AddHrefPipe } from './pipe/add-href.pipe';
import { CurrencyFormatPipe } from './pipe/currency-format.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { UcFirstPipe } from './pipe/uc-first.pipe';

import { ArticlesListComponent } from './component/articles-list/articles-list.component';
import { ContactFormInlineComponent } from './component/contact-form-inline/contact-form-inline.component';
import { GoogleReviewListComponent } from './component/google-review-list/google-review-list.component';
import { CollectionFilterPipe } from './pipe/collection-filter.pipe';
import { StaticContentPipe } from './pipe/static-content.pipe';

import { MatDialogModule } from '@angular/material/dialog';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { DataService } from './data.service';
import { PageGuard } from './guard/page.guard';
import { HttpConfigHeaderInterceptor } from './interceptor/http-config-header.interceptor';
import { HttpDebugInterceptor } from './interceptor/http-debug.interceptor';
import { LazyDirective } from './lazy.directive';
import { CookiesHelper } from './lib/cookies-helper.lib';
import { MetaService } from './meta.service';
import { AnalyticsGaService } from './module/ng-analytics/service/service/analytics-ga.service';
import { HammerConfig } from './provider/hammer-config.provider';
import { LazyImageObserverService } from './service/lazy-image-observer.service';
// import { configFactory } from './config.service';

const matModules = [
  MatCardModule,
  MatGridListModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatListModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatDialogModule,
];

const components = [
  AppComponent,
  PropertyComponent,
  PropertyEnergyEfficiencyComponent,
  HomeComponent,
  BoxComponent,
  FooterComponent,
  HeaderComponent,
  SearchComponent,
  PreviewComponent,
  PropertySegmentComponent,
  MapComponent,
  FeaturesComponent,
  NotFoundComponent,
  PagesComponent,
  CookieComponent,
  ShareComponent,
  DynamicUrlsComponent,
  GalleryComponent,
  ContactFormComponent,
  RecaptchaComponent,
  AlertDialogComponent,
  ArticleComponent,
  GoogleReviewListComponent,
  ArticlesListComponent,
  ContactFormInlineComponent,
];

const pipes = [
  DecodeUriComponentPipe,
  SafeHtmlPipe,
  TruncatePipe,
  UcFirstPipe,
  CurrencyFormatPipe,
  AddHrefPipe,
  SortByPipe,
  StaticContentPipe,
  CollectionFilterPipe,
];

const directives = [LazyDirective];

const services = [
  DataService,
  MetaService,
  CookiesHelper,
  AnalyticsGaService,
  LazyImageObserverService,
];

const guards = [PageGuard];

const tokens = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigHeaderInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpDebugInterceptor, multi: true },
  { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  // { provide: LOCALE_ID, useValue: 'it-IT' },
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: configFactory,
  //   deps: [Injector],
  //   multi: true,
  // },
];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    ...matModules,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    StoreModule,
    NgAnalyticsModule,
  ],
  providers: [...services, ...guards, ...tokens],
  bootstrap: [AppComponent],
})
export class AppModule {}
