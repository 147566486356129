import { NgModule } from '@angular/core';
import { INITIAL_STATE, rootReducer } from './store';
import { IAppState } from '../../interface/app-state.interface';
import {
  NgReduxModule,
  DevToolsExtension,
  NgRedux,
} from '@angular-redux/store';
import { Middleware, StoreEnhancer } from 'redux';
import { env } from '../../lib/config.lib';

const debugRedux: boolean = env.debug.redux;

const middlewares: Middleware[] = [];

const storeEnhancers: StoreEnhancer<any>[] = [];

@NgModule({
  imports: [NgReduxModule],
  providers: [],
})
export class StoreModule {
  constructor(
    public store: NgRedux<IAppState>,
    private devTools: DevToolsExtension
  ) {
    // if (typeof window !== 'undefined') {
    //   window['sm'] = this;
    // } else {
    //   global['sm'] = this;
    // }

    if (devTools.isEnabled() && debugRedux) {
      storeEnhancers.push(devTools.enhancer());
    }

    store.configureStore(
      rootReducer,
      INITIAL_STATE,
      middlewares,
      storeEnhancers
    );
  }
}
