import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../data.service';
import { client } from '../../decorator/client.decorator';
import { ISimpleMap } from '../../interface/simple-map.interface';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { Obj } from '../../lib/obj.lib';
import { StaticContentPipe } from '../../pipe/static-content.pipe';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Input() public propertyId!: number;
  @Input() public propertyTitle!: string;
  @Input() public propertySeoUrl!: string;
  @Input() public propertyRef!: number;
  @Input() public formHidden = true;
  @Output() public formIsVisibleEmitter = new EventEmitter<boolean>();

  public profileForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl('', Validators.required),
    data: new UntypedFormControl(''),
    recaptcha: new UntypedFormControl(''),
    privacy: new UntypedFormControl('', Validators.required),
  });

  public submitted = false;
  public autoUnsubscribe = new AutoUnsubscribe();
  public sent = false;
  public isMobile = false;

  constructor(
    public service: DataService,
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.profileForm.valueChanges.subscribe(this.onFormChanges);
    this.checkAgent();
  }

  public ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  public submitEnabled = () =>
    this.profileForm.valid && this.profileForm.value.recaptcha;

  public openSuccessDialog = (): void => {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '585px',
      data: {
        message: `Grazie per avermi contattato!<br>Ti ricontatterò al più presto.<br><br><span>Michelangelo Parodi</span>`,
        bt: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.formVisible(false);
      this.changeDetectorRef.markForCheck();
    });
  };

  public whatsAppHref = () => {
    const staticContent = new StaticContentPipe();

    const phoneNumber = staticContent.transform(
      'PROPERTY.CTA_CONTATTACI.PHONE'
    );

    const dominio = staticContent.transform('SHARED.HEADER.DOMAIN');

    const seoUrl = `https://${dominio}/immobili-in-vendita-a-savona/${this.propertySeoUrl}?web`;

    // todo: move in templates
    const text = `Ciao Michelangelo,\nsono interessato alla proprietà con riferimento ${this.propertyRef}.\n${seoUrl}`;

    const api = this.isMobile ? 'api' : 'web';

    const url = `https://${api}.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;

    return encodeURI(url);
  };

  @client
  public checkAgent() {
    this.isMobile =
      navigator.userAgent.match(/Android|iPhone/i) &&
      !navigator.userAgent.match(/iPod|iPad/i)
        ? true
        : false;
  }

  private onFormChanges = (data: any): void => {
    Object.keys(this.profileForm.controls).forEach((controlName: string) => {
      const control = this.profileForm.controls[controlName];

      control.updateValueAndValidity({ onlySelf: true });
    });

    this.profileForm.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false,
    });

    // this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  };

  public onSubmit() {
    if (this.submitEnabled()) {
      this.submitted = true;

      const formToAppointmentMap: ISimpleMap = {
        firstName: 'name',
        lastName: 'surname',
        phone: 'telephone',
        data: 'date',
        recaptcha: 'recaptchaResponse',
        privacy: 'privacyAccepted',
      };

      const payload = {
        ...(Obj.mapKeys(
          this.profileForm.value,
          formToAppointmentMap
        ) as Object),
        propertyId: this.propertyId,
      };

      const subscription = this.service
        .postContactForm(payload)
        .subscribe((data) => {
          this.openSuccessDialog();
          this.changeDetectorRef.markForCheck();
        });

      this.autoUnsubscribe.add(subscription);
    }
  }

  public formVisible(visible: boolean): void {
    this.formIsVisibleEmitter.emit(visible);
  }
}
