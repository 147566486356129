import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';
import { IInfo } from '../../../interface/info.interface';
import { IGoogleReview } from '../../../component/google-review-list/google-review-list.component';

@Injectable()
export class InfoActions {
  static SET_INFO = 'SET_INFO';
  static SET_HEADER = 'SET_HEADER';
  static SET_FOOTER = 'SET_FOOTER';
  static SET_REVIEWS = 'SET_REVIEWS';

  static setInfo(infoObj: any): AnyAction {
    return {
      type: InfoActions.SET_INFO,
      payload: infoObj,
    };
  }

  static setHeader(headerObj: IInfo[]): AnyAction {
    return {
      type: InfoActions.SET_HEADER,
      payload: headerObj,
    };
  }

  static setFooter(footerObj: any): AnyAction {
    return {
      type: InfoActions.SET_FOOTER,
      payload: footerObj,
    };
  }

  static setReviews(reviews: IGoogleReview[]): AnyAction {
    return {
      type: InfoActions.SET_REVIEWS,
      payload: reviews,
    };
  }
}
