<div class="sc-gallery">
  <div
    *ngIf="imgUrl"
    class="sc-property__image sc-gallery__image sc-gallery__image--preview"
    [ngClass]="property.sold ? 'sc-gallery__image--preview__greyscale' : ''"
  >
    <img
      *ngIf="!previewMode"
      class="sc-gallery__image--preview__img"
      [src]="getImgPath(imgUrl, resizedPresetName)"
      [alt]="imgNameToAlt(imgUrl)"
      [title]="imgNameToAlt(imgUrl)"
      [srcset]="
        getSrcSet(
          { url: imgUrl, loaded: true, opacity: 1, zIndex: 0 },
          assetWidthPresets
        )
      "
    />

    <img
      appLazy
      *ngIf="previewMode"
      class="sc-gallery__image--preview__img lazy"
      [src]="getImgPath(imgUrl, resizedPresetName)"
      [alt]="imgNameToAlt(imgUrl)"
      [title]="imgNameToAlt(imgUrl)"
    />

    <div *ngIf="property?.sold" class="sc-gallery__sold">
      <span class="sc-gallery__sold__text">VENDUTA</span>
    </div>

    <div
      class="sc-preview__negotiation"
      *ngIf="property?.negotiationActive && !property?.sold"
    >
      <h5>In trattativa</h5>
    </div>
  </div>

  <div *ngIf="fullScreen | async" class="sc-gallery__bg--fullscreen"></div>

  <div
    *ngFor="let asset of galleryAssets; let i = index"
    class="sc-property__image sc-gallery__image"
    [ngClass]="{
      'sc-gallery__image--fullscreen': isFs(i),
      'sc-gallery__image--greyscale': property?.sold
    }"
    [style.z-index]="asset.zIndex"
    [style.opacity]="asset.opacity"
  >
    <img
      class="sc-gallery__image__img"
      [src]="getLoadedImgPath(asset, resizedPresetName)"
      [alt]="imgNameToAlt(asset.url)"
      [srcset]="getSrcSet(asset, assetWidthPresets)"
      [title]="getLoadedImgPath(asset, resizedPresetName).split('-').join(' ')"
    />
    <div *ngIf="i === currentIndex && asset.loading" class="sc-search__busy">
      <div class="sc-search__busy__spinner-cnt">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!previewMode" class="sc-property__bt--fullscreen mat-full-screen">
  <button
    mat-mini-fab
    matTooltipPosition="above"
    matTooltip="{{ fsLabel }}"
    (click)="toggleFullScreen()"
  >
    <mat-icon>{{ (fullScreen | async) ? "close" : "fullscreen" }}</mat-icon>
  </button>
</div>

<div
  *ngIf="navArrowsEnabled"
  class="sc-gallery__icons__container"
  (swipeLeft)="swapAhead()"
  (swipeRight)="swapBackwards()"
  data-mc-options='{ "touchAction": "pan-y" }'
>
  <div class="sc-gallery__cycle-left" (click)="swapBackwards()"></div>
  <div class="sc-gallery__cycle-right" (click)="swapAhead()"></div>
</div>

<div
  *ngIf="navDotsEnabled"
  class="sc-gallery__dots__container"
  [style.position]="(fullScreen | async) ? 'fixed' : 'absolute'"
>
  <div
    *ngFor="let asset of galleryAssets; let j = index"
    [style.width.pc]="100 / (galleryAssets.length - 1)"
    class="sc-gallery__dot"
    [ngClass]="{ 'sc-gallery__dot--selected': j === currentIndex }"
    (click)="moveToIndex(j)"
  ></div>
</div>

<div *ngIf="detailsEnabled" class="sc-preview__details">
  <h5
    [ngClass]="property.sold ? 'sc-preview__rif--greyscale' : 'sc-preview__rif'"
  >
    <span class="sc-preview__rif__category">
      {{ property?.immobileCategoria?.label | uppercase }} |
    </span>
    <span class="sc-preview__rif__value">{{ property?.refId }}</span>
  </h5>

  <h5
    [ngClass]="
      property.sold ? 'sc-preview__pricegreyscale' : 'sc-preview__price'
    "
    *ngIf="!property.priceReserved"
  >
    &euro; {{ property?.price | currencyFormat }}
  </h5>

  <h5
    class="sc-preview__reserved"
    *ngIf="property.priceReserved && !property.sold"
  >
    TRATTATIVA RISERVATA
  </h5>
</div>

<!-- *ngIf="property.negotiationActive && !property.sold" -->
