import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { REQUEST } from '@nguniversal/express-engine/tokens';
// import {Request as ExpressRequest} from 'express';

@Injectable()
export class HttpConfigHeaderInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(REQUEST) private serverRequest: any) {} // TODO:

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.serverRequest) {
      return next.handle(req);
    } else {
      const currentHeaders = req.headers
        .keys()
        .reduce((a, c) => ({ ...a, [c]: req.headers.get(c) }), {});

      const newReq = req.clone({
        headers: new HttpHeaders({
          ...(currentHeaders || {}),
          'Referer-Domain': this.serverRequest.hostname,
          auth_mode_internal: 'on',
        }),
      });

      return next.handle(newReq);
    }
  }
}
