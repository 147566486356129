<div class="sc-box">
  <div class="sc-box__title">
    <h1>{{ 'HOME.BOX.TITLE' | staticContent }}</h1>
  </div>

  <div class="sc-box__body">
    <div class="sc-box__body__background-blur"></div>

    <div class="sc-box__body__button button-row">
      <a
        href="{{ cta.URL }}"
        [appNgAnalyticsSendEvent]="[
          'Click',
          'box.bt2.nuove-costruzioni',
          'HOME.BOX.CTA_NEW_CONSTRUCTIONS.NAME' | staticContent
        ]"
        class="sc-button mat-cta"
        mat-raised-button
        *ngFor="let cta of 'HOME.BOX.CTAS' | staticContent"
      >
        <div class="sc-box__bt--main">
          <mat-icon *ngIf="cta.ICON">{{ cta.ICON }}</mat-icon>
          <span>{{ cta.NAME }}</span>
        </div>
      </a>
    </div>

    <div class="sc-box__body__search">
      <form class="form-inline" autocomplete="off">
        <mat-form-field class="mat-input-value-text mat-input-element-cta">
          <input
            class="mat-input-element-cta"
            type="text"
            placeholder="{{ 'HOME.BOX.INPUT_SEARCH_TX' | staticContent }}"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <a
          [href]="
            ('HOME.BOX.CTA_SUBMIT.URL' | staticContent) + getUrl(cities | async)
          "
          [appNgAnalyticsSendEvent]="[
            'Click',
            'box.bt3.vai',
            'Vai',
            this.myControl.value
          ]"
          class=" mat-cta-submit"
          mat-raised-button
        >
          <mat-icon>{{ 'HOME.BOX.CTA_SUBMIT.ICON' | staticContent }}</mat-icon>
          <span>{{ 'HOME.BOX.CTA_SUBMIT.NAME' | staticContent }}</span>
        </a>

        <!--<mat-form-field class="example-form-field">-->
        <!--<input matInput type="text" placeholder="Clearable input" [(ngModel)]="value">-->
        <!--<button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">-->
        <!--<mat-icon>close</mat-icon>-->
        <!--</button>-->
        <!--</mat-form-field>-->
      </form>
    </div>
  </div>
</div>
