import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { url } from 'inspector';
import { defaultUrlMatcher } from '../lib/url.lib';
import { StaticContentPipe } from '../pipe/static-content.pipe';
@Injectable()
export class SeocasaMatcher {
  public static searchMatcher(
    segments: UrlSegment[],
    group: UrlSegmentGroup,
    route: Route
  ) {
    const staticRoutes = StaticContentPipe.getContent('SHARED.HEADER.MENU');

    // todo: move in config
    const path = ['categoria', 'comune'];

    const currentRoute = '/' + segments[0].path;

    // fixme: index 1 represents the properties search dynamic route
    if (staticRoutes[1].url === currentRoute) {
      if (segments.length === 1) {
        return { consumed: segments };
      }

      if (segments.length > 1 && path.indexOf(segments[1].path) > -1) {
        return {
          consumed: segments,
          posParams: {
            categoryPage: segments[1],
            value: segments[2],
          },
        };
      }

      return null;
    }

    return null;
  }

  public static propertyMatcher(
    segments: UrlSegment[],
    group: UrlSegmentGroup,
    route: Route
  ) {
    const staticRoutes = StaticContentPipe.getContent('SHARED.HEADER.MENU');

    const currentRoute = '/' + segments[0].path;

    return segments.length === 2 && staticRoutes[1].url === currentRoute
      ? {
          consumed: segments,
          posParams: {
            seoUrl: segments[1],
          },
        }
      : null;
  }

  public static toggleMatcher =
    (path: string, featureName: string) =>
    (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
      const staticRoutes = StaticContentPipe.getFeatures();

      route.path = path;

      return staticRoutes[featureName]
        ? defaultUrlMatcher(segments, group, route)
        : null;
    };
}
