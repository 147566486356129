<div class="sc-energy-efficiency" *ngIf="!isNaN(value)">
  <div class="sc-energy-efficiency__bars">
    <div
      class="sc-energy-efficiency__bar"
      *ngFor="let range of data; let i = index"
      [ngStyle]="computeStyle(range)"
    >
      <div
        class="sc-energy-efficiency__bar__grade"
        [innerHTML]="getRangeLabel(range, i)"
      ></div>
      <div class="sc-energy-efficiency__bar__value">{{ range.label }}</div>
    </div>
  </div>

  <div class="sc-energy-efficiency__main-value">
    <div class="sc-energy-efficiency__main-value__bar">
      <div *ngFor="let range of data; let i = index">
        <div
          class="sc-energy-efficiency__main-value__bar-item"
          *ngIf="isComputedRange(range)"
          [ngStyle]="computeMainBarStyle(value)"
        >
          <span>{{ value }}{{ isNewAPE ? " EP" : "" }}</span>
          <div class="sc-energy-efficiency__main-value__bar-item__mask-a"></div>
          <div class="sc-energy-efficiency__main-value__bar-item__mask-b"></div>
        </div>

        <div *ngIf="!isComputedRange(range)">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
