<article class="sc-article p-5">
  <h1>{{ (article | async)?.title }}</h1>
  <h2>{{ (article | async)?.subtitle }}</h2>

  <img
    *ngIf="article | async"
    appLazy
    class="sc-article__image lazy"
    [src]="getImgPath((article | async)?.coverImage, 'w800')"
    [alt]="(article | async)?.coverImageAlt"
    [title]="(article | async)?.coverImageTitle"
  />

  <section
    class="sc-article__text"
    [innerHTML]="(article | async)?.content | decodeUriComponent | safeHtml"
  ></section>
</article>

<!--<app-articles-list class="article__list"></app-articles-list>-->
