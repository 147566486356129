import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface IEfficiencyRange {
  color: string;
  label: string;
  max: number;
  prefix: string;
  value: number;
}

export const efficiencyRangeData: IEfficiencyRange[] = [
  {
    prefix: '<',
    max: 175,
    label: 'A+',
    color: '#006f2d',
    value: 15,
  },
  { prefix: '<', max: 150, label: 'A', color: '#007f3d', value: 35 },
  { prefix: '<', max: 125, label: 'B', color: '#2c9f3d', value: 50 },
  { prefix: '<', max: 100, label: 'C', color: '#9dcb3c', value: 75 },
  { prefix: '<', max: 75, label: 'D', color: '#fff200', value: 100 },
  { prefix: '<', max: 50, label: 'E', color: '#f7af1d', value: 125 },
  { prefix: '<', max: 35, label: 'F', color: '#ed6823', value: 150 },
  {
    prefix: '>',
    max: 15,
    label: 'G',
    color: '#e31d23',
    value: 100000,
  }, // 175
];

export const newEfficiencyRangeData: IEfficiencyRange[] = [
  {
    prefix: '≤',
    max: 1.5,
    label: 'A4',
    color: '#758792',
    value: 0.4,
  },
  {
    prefix: '≤',
    max: 1.35,
    label: 'A3',
    color: '#208f85',
    value: 0.6,
  },
  {
    prefix: '≤',
    max: 1.2,
    label: 'A2',
    color: '#1e8f6f',
    value: 0.8,
  },
  { prefix: '≤', max: 1.05, label: 'A1', color: '#6bc335', value: 1 },
  {
    prefix: '≤',
    max: 0.9,
    label: 'B',
    color: '#abdd7e',
    value: 1.2,
  },
  {
    prefix: '≤',
    max: 0.75,
    label: 'C',
    color: '#fdea00',
    value: 1.5,
  },
  { prefix: '≤', max: 0.6, label: 'D', color: '#fec801', value: 2 },
  {
    prefix: '≤',
    max: 0.45,
    label: 'E',
    color: '#f38004',
    value: 2.6,
  },
  {
    prefix: '≤',
    max: 0.3,
    label: 'F',
    color: '#ef4d42',
    value: 3.5,
  },
  {
    prefix: '≥',
    max: 0.15,
    label: 'G',
    color: '#ec3d01',
    value: 100000,
  },
];

@Component({
  selector: 'app-property-energy-efficiency',
  templateUrl: './property-energy-efficiency.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyEnergyEfficiencyComponent {
  @Input() public value!: number;
  @Input() public isNewAPE!: boolean;

  public data!: IEfficiencyRange[];

  public ngOnInit() {
    this.data = this.isNewAPE ? newEfficiencyRangeData : efficiencyRangeData;
  }

  public isNaN: (arg: any) => any = Number.isNaN;

  public computeStyle(range: IEfficiencyRange): any {
    const width = this.isNewAPE
      ? `${(175 - 175 * range.max) * (100 / 175) * 0.4 + 60}%`
      : `${(175 - range.max) * (100 / 175) * (40 / 100) + 60}%`;

    return {
      width,
      'background-color': range.color,
    };
  }

  public computeRange(value: number): IEfficiencyRange {
    return this.data.find(
      (range: IEfficiencyRange) => range.value >= value
    ) as IEfficiencyRange;
  }

  public computeMainBarStyle(value: number): any {
    return {
      'background-color': this.computeRange(value).color,
    };
  }

  public getRangeLabel(range: IEfficiencyRange, i: number): string {
    const measure = this.isNewAPE ? '' : 'KWh/m<sup>2';

    const value =
      i === this.data.length - 1 ? this.data[i - 1].value : range.value;

    return `${range.prefix} ${value} ${measure}</sup>`;
  }

  public isComputedRange(range: IEfficiencyRange) {
    const computedRange = this.computeRange(this.value);

    if (typeof computedRange === 'undefined') {
      console.warn(`Unable do determine range for value ${this.value}`);
    }

    return typeof this.value !== 'undefined' &&
      this.value !== null &&
      typeof computedRange !== 'undefined'
      ? range.label === this.computeRange(this.value).label
      : false;
  }
}
