import { Injectable } from '@angular/core';
import { StaticContentPipe } from './pipe/static-content.pipe';

@Injectable()
export class PrintProperty {
  public static print(data: any, httpBase: string) {
    let c: string;
    let imgs: any;

    const staticContent = new StaticContentPipe();

    c = '<head>';
    c +=
      '<link href="http://fonts.googleapis.com/css?family=Raleway:600,200" rel="stylesheet" type="text/css">';

    /*
    DEFINIZIONE STILI
    */
    c += '<style>';
    c += '.sx{float:left}.dx{float:right;}.clearme{clear:both;}';
    c += 'h2{color:#be1622;} .fl{float:left;}.fr{float:right;}';
    c +=
      "body{font-family: 'Raleway',sans-serif; /*font-size: 1.1em;*/padding:10%;margin:0;}";
    c += '.content .prop{}';
    c +=
      '.content .prop .de {color: #2d88cb; width: 48%; float: left; text-align: right;}';
    c +=
      '.content .prop .va {color: #444; /*width: 45%*/; float: left; margin-left: 5%;}';
    c += '.content .sx{width:50%;} .content .dx{width:50%;}';

    c += '.imgs img{width:48%;}';
    c += '.imgs .im1{margin-left:4%;}';
    c +=
      '._logo{display:block;width: 40px; max-height: auto;  margin-right: 10px; }';
    c +=
      '.header{height: 50px; color: #155DA0; font-size: 45px; padding: 10px; vertical-align: baseline; border-bottom: 1px solid #155DA0;}';
    // c += '@media print {'body {background-color: #155DA0 !important;}' }';
    c += '</style>';

    c += '</head>';
    c += '<body onload="window.print();">';

    /*
    HEADER
    */

    const logoColor = staticContent.transform('SHARED.HEADER.PATH_LOGO_COLOR');

    c += '<div class="header">';
    c +=
      '<div class=""><img class="_logo fl" src="' +
      httpBase +
      '/' +
      logoColor +
      '"></div>';

    const agency = staticContent.transform('SHARED.HEADER.AGENCY_NAME');

    c += '<div class="fl" style="color: #155DA0">' + agency + '</div>';
    c += '</div>';

    /*
    TITOLO E DESCRIZIONE
    */
    c += '<h4>' + data.metaTitle + '</h4>';

    /*
    CARATTERISTICHE COLONNA SINISTRA
    */
    c += '<div class="content">';
    c += '<div class="sx">';
    c += '<b>PREZZO:</b> ' + this.formatPrice(data.price) + '<br>';
    c += '<b>Mq Interni: </b> ' + data.surfaceSqm + ' m<sup>2</sup><br>';

    if (data.cellarSqm) {
      c += '<b>Mq Cantina: </b> ' + data.cellarSqm + ' m<sup>2</sup><br>';
    }

    if (data.gardenSqm) {
      c += '<b>Mq Giarino: </b> ' + data.gardenSqm + ' m<sup>2</sup><br>';
    }

    c +=
      '<b>Camere da letto: </b>' +
      data.bedroomsCount +
      '<br>' +
      '<b>Bagni:</b> ' +
      data.bathroomsCount +
      '<br>';
    c += '</div>';

    /*
    CARATTERISTICHE COLONNA DESTRA
    */
    c +=
      '<div class="sx">' + data.featureBalcony
        ? '<b>Balcone:</b> si<br>'
        : '' + data.featureBox
        ? '<b>Garage:</b> si<br>'
        : '' + data.featureCarPark
        ? '<b>Posto auto:</b> si<br>'
        : '' +
          // data.feature_carattic ?  '<b>Attico:</b> si<br>' : ''  +
          data.featureLift
        ? '<b>Ascensore:</b> si<br>'
        : '' + data.featureVeranda
        ? '<b>Veranda:</b> si<br>'
        : '' + '</div>';

    c += '<div class="clearme"></div>';

    /*
    IMMAGINI
    */
    imgs = '<br/><div class="imgs">';
    imgs +=
      '<img src="' +
      httpBase +
      '/uploads/w1500/' +
      data.assets[0].fileName +
      '">';
    imgs +=
      '<img class="im1" src="' +
      httpBase +
      '/uploads/w1500/' +
      data.assets[1].fileName +
      '">';
    imgs += '</div>';

    c += imgs;
    imgs = '<br/><div class="imgs">';
    imgs +=
      '<img src="' +
      httpBase +
      '/uploads/w1500/' +
      data.assets[2].fileName +
      '">';
    imgs +=
      '<img class="im1" src="' +
      httpBase +
      '/uploads/w1500/' +
      data.assets[3].fileName +
      '">';
    imgs += '</div>';
    c += imgs;

    c += '<p>' + data.metaDescription + '</p>';
    c += '</body>';

    const w = window.open(
      '#',
      'mywin',
      'left=20,top=20,width=750,height=800,scrollbars=yes,menubar=yes,toolbar=yes'
    );

    if (!w) {
      return;
    }

    w.document.open();
    w.document.write(c);
    w.document.close();

    // w.document.print();
  }
  public static formatPrice(
    amount: any,
    decimalCount = 2,
    decimal = ',',
    thousands = '.'
  ) {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = Number(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();

      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - Number(i))
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }
}
