<div class="sc-footer" *ngIf="toggle('pages.general.footer_layout1')">
  <app-dynamic-urls *ngIf="toggle('pages.general.footer_links')">
  </app-dynamic-urls>

  <div class="sc-footer--info">
    <div class="sc-footer--info--business">
      {{ "SHARED.FOOTER.RAGIONE_SOCIALE" | staticContent }}
      <br />
      {{ "SHARED.FOOTER.PHONE1" | staticContent }}
      {{ "SHARED.FOOTER.PHONE2" | staticContent }}
      {{ "SHARED.FOOTER.EMAIL" | staticContent }}
    </div>
    <div class="sc-footer--info--logo">
      <a href="http://www.fiaip.it" target="_blank">
        <img
          appLazy
          class="lazy"
          src="/assets/fiaip.png"
          alt="logo fiaip"
          width="130px"
        />
      </a>
    </div>
  </div>

  <div class="sc-footer--privacy">
    <a
      href="{{ 'SHARED.FOOTER.PRIVACY_POLICY_URL' | staticContent }}"
      target="_blank"
      title="Privacy Policy"
      [appNgAnalyticsSendEvent]="[
        'Click',
        'footer.privacy-policy',
        'Privacy Policy'
      ]"
    >
      privacy policy
    </a>
    <script type="text/javascript">
      (function (w, d) {
        var loader = function () {
          var s = d.createElement("script"),
            tag = d.getElementsByTagName("script")[0];
          s.src = "https://cdn.iubenda.com/iubenda.js";
          tag.parentNode.insertBefore(s, tag);
        };
        if (w.addEventListener) {
          w.addEventListener("load", loader, false);
        } else if (w.attachEvent) {
          w.attachEvent("onload", loader);
        } else {
          w.onload = loader;
        }
      })(window, document);
    </script>
  </div>

  <div class="sc-footer--createdwith">
    created with
    <span
      ><a href="https://seocasa.it" title="seocasa.it" target="_blank"
        ><img
          src="/assets/logo/sc-logo-outline.svg"
          alt="seocasa.it"
          title="seocasa.it" /></a
    ></span>
  </div>
</div>

<div class="sc-footer-layout2" *ngIf="toggle('pages.general.footer_layout2')">
  <app-dynamic-urls *ngIf="toggle('pages.general.footer_links')">
  </app-dynamic-urls>

  <div class="sc-footer-layout2__contact">
    <span class="sc-footer-layout2__contact__title">Contattaci ora</span>

    <!-- contatti -->
    <div
      class="sc-footer-layout2__contact__item"
      *ngFor="let param of 'SHARED.HEADER.CONTACT' | staticContent"
    >
      <i class="material-icons" [ngClass]="param.mobile ? '' : 'hideOnMobile'"
        >{{ param.icon }}
      </i>
      <span [ngClass]="param.mobile ? '' : 'hideOnMobile'">
        <span [ngClass]="param.mobile ? '' : 'hideOnMobile'">{{
          param.label
        }}</span>

        <span *ngIf="param.value" [ngClass]="param.mobile ? '' : 'hideOnMobile'"
          >:
        </span>
        <a *ngIf="param.href" [href]="param.href">{{ param.value }} </a>
        <span class="" *ngIf="!param.href">{{ param.value }} </span>
      </span>
    </div>

    <!-- contatti -->
  </div>

  <div class="sc-footer-layout2__bottom row">
    <span class="col-md-4">
      <img
        src="{{ 'SHARED.HEADER.PATH_LOGO' | staticContent }}"
        alt="{{ 'SHARED.HEADER.AGENCY_NAME' | staticContent }}"
      />
    </span>

    <span class="col-md-4">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    </span>
    <span class="col-md-4">
      <app-share></app-share>
    </span>
  </div>

  <div class="sc-footer-layout2__copyright">
    © Homeradar Srl - P.IVA 000000000000
  </div>
</div>
