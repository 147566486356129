import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import * as Cookies from 'es-cookie';
import { env } from './config.lib';

interface ICookieOptions {
  maxAge: number;
  httpOnly: boolean;
  secure: boolean;
}

@Injectable()
class CookiesHelper {
  public constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Optional() @Inject(RESPONSE) private response: any
  ) {}

  public get policyAccepted() {
    return !!this.get(env.cookies.privacyPolicy.key);
  }

  public setPolicyAccepted() {
    this.set(env.cookies.privacyPolicy.key, 'true');
  }

  public get(name: string) {
    if (typeof document !== 'undefined') {
      return Cookies.get(name);
    } else {
      if (!this.request.cookies) {
        console.warn('Unable to access cookies');
        return;
      }
      return this.request.cookies[name];
    }
  }

  public set(
    name: string,
    value: string,
    options: ICookieOptions | undefined = undefined
  ) {
    if (typeof document !== 'undefined') {
      Cookies.set(name, value, options);
    } else {
      return this.response.cookie(name, value, options);
    }
  }
}

export { ICookieOptions, CookiesHelper };
