import { Reducer } from 'redux';

const initialState = {
  noMatchFound: false,
  noScroll: false,
  galleryFullScreen: false,
};

export const configReducer: Reducer<any> = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case 'SET_CONFIG':
      return action.payload;

    case 'SET_NOT_FOUND':
      return {
        ...state,
        noMatchFound: true,
      };

    case 'SET_NO_SCROLL':
      return {
        ...state,
        noScroll: action.payload,
      };

    case 'GALLERY_TOGGLE_FULLSCREEN':
      return {
        ...state,
        galleryFullScreen: !state.galleryFullScreen,
      };
  }

  return state;
};
