import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Obj } from './lib/obj.lib';
import { env } from './lib/config.lib';
import { Observable } from 'rxjs';
// import { Content } from '@angular/compiler/src/render3/r3_ast';
// import {Content} from '@angular/compiler/'
// import { Content } from '@angular/core';
import { IPropertySegment } from './interface/property-segment.type';
import { Cities, PropertyCategories, PropertyTypes } from './types';

type Content = {};

const urls: any = {
  basePath:
    typeof window !== 'undefined'
      ? env.paths.clientService
      : env.paths.serviceTs,

  entityCollection: (entityName: string): string =>
    `${urls.basePath}/entity/${entityName}`,

  entityCollectionField: (entityName: string, fieldName: string): string =>
    `${urls.basePath}/entity/${entityName}/field/${fieldName}`,

  entityCollectionTwoFields: (
    entityName: string,
    fieldNameA: string,
    fieldNameB: string
  ): string =>
    `${urls.basePath}/entity/${entityName}/fields/${fieldNameA}/${fieldNameB}`,

  // entities
  propertyCollection: () => urls.entityCollection('Immobile'), // todo: entity enum ?
  propertyTypeCollection: () => urls.entityCollection('ImmobileTipologia'),
  propertyCategoryCollection: () => urls.entityCollection('ImmobileCategoria'),
  articlesCollection: () => urls.entityCollection('Articolo'),
  filtroImmobiliCollection: () => urls.entityCollection('FiltroImmobile'),
  footerLinks: () => `${urls.basePath}/footer/test`,
  contenutoCollection: () => urls.entityCollection('Contenuto'),

  // fields
  cities: () => urls.entityCollectionField('Immobile', 'city'),
  areas: () => urls.entityCollectionField('Immobile', 'area'),

  // static
  staticJson: (fileName: string): string =>
    `${urls.basePath}/static-data/${fileName}.json`,

  // googleReviews: () => urls.staticJson('google-reviews')

  cityAndArea: () => urls.entityCollectionTwoFields('Immobile', 'city', 'area'),
};

@Injectable()
export class DataService {
  private basePath =
    typeof window !== 'undefined' ? env.paths.clientService : env.paths.service;

  private urlContactForm = this.basePath + '/form/property/info'; // todo:
  private urlSimpleContactForm = this.basePath + '/form/property/info/simple'; // todo:

  constructor(private http: HttpClient) {}

  private defaultParams: { [param: string]: boolean | number | string } = {};

  /* GET requests */

  public getImmobili(parameters: any = {}): Observable<any> {
    // TODO: interface
    const selectFields: (keyof Partial<any>)[] = [
      'id',
      'seoUrl',
      'metaTitle',
      'area',
      'refId',
      'city',
      'active',
      'price',
      'priceReserved',
      'assets',
      'sold',
      'surfaceSqm',
      'immobileCategoria',
      'negotiationActive',
    ];

    const relations = ['assets'];

    const params = Obj.toUrlParams({
      ...this.defaultParams,
      ...parameters,
      __select: selectFields.join(','),
      __relations: relations.join(','),
    });

    const url = `${urls.propertyCollection()}?${params}`; // todo: move params in fn

    return this.http.get(url);
  }

  public getDettaglio(seoUrl: string): Observable<any> {
    const params = Obj.toUrlParams({
      __ddsNoIds: true,
      seoUrl,
      active: 1,
      __relations: ['assets'].join(''),
    });

    const url = `${urls.propertyCollection()}?${params}`;

    return this.http.get(url);
  }

  // public getAssets(id: number): Observable<any> {
  //   const params = Obj.toUrlParams({
  //     ...this.defaultParams,
  //     'immobilis:id': id,
  //     __select: 'id,fileName,sortOrder'
  //   });
  //   const url = `${this.urlAssets}?${params}`;
  //   return this.http.get(url);
  // }

  public getPropertySegment(
    key: string,
    value: string
  ): Observable<IPropertySegment> {
    const fields = [
      'id',
      'seoUrl',
      'metaTitle',
      'area',
      'refId',
      'city',
      'active',
      'price',
      'priceReserved',
      'assets',
      'sold',
      'surfaceSqm',
      'immobileCategoria',
      'negotiationActive',
    ];

    const relations = ['assets'];

    const params = Obj.toUrlParams({
      ...this.defaultParams,
      [key]: value,
      active: 1,
      __select: fields.join(','),
      __relations: relations.join(','),
    });

    return this.http.get<IPropertySegment>(
      `${urls.propertyCollection()}?${params}`
    );
  }

  public getPage(seoUrl: string): Observable<Content[]> {
    const params = Obj.toUrlParams({
      ...this.defaultParams,
      seoUrl,
    });

    const url = `${urls.contenutoCollection()}?${params}`;

    return this.http.get<Content[]>(url);
  }

  public getArticle(seoUrl: string): Observable<any> {
    const params = Obj.toUrlParams({
      ...this.defaultParams,
      seoUrl,
    });

    const url = `${urls.articlesCollection()}?${params}`;

    return this.http.get(url);
  }

  public getArticles(
    limit: number,
    page: number,
    pageSize: number
  ): Observable<any> {
    const fields = [
      'id',
      'title',
      'seoUrl',
      'metaTitle',
      'subtitle',
      'coverImage',
      'coverImageAlt',
      'coverImageTitle',
    ];

    const params = Obj.toUrlParams({
      ...this.defaultParams,
      __select: fields.join(','),
      __limit: limit,
      __page: page,
    });

    const url = `${urls.articlesCollection()}?${params}`; // &__pageSize=${pageSize}

    return this.http.get(url);
  }

  public getCities(): Promise<Cities> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    return this.http.get<Cities>(`${urls.cities()}?${params}`).toPromise();
  }

  public getPropertyTypes(): Promise<PropertyTypes> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    return this.http
      .get<PropertyTypes>(`${urls.propertyTypeCollection()}?${params}`)
      .toPromise();
  }

  public getPropertyCategories(): Promise<PropertyCategories> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    return this.http
      .get<PropertyCategories>(`${urls.propertyCategoryCollection()}?${params}`)
      .toPromise();
  }

  public getPresetUrlsList(): Observable<any> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    return this.http.get(`${urls.filtroImmobiliCollection()}?${params}`);
  }

  public getFooterLinksList(): Observable<any> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    return this.http.get(`${urls.footerLinks()}`);
  }

  public getPresetUrl(seoUrl: string): Observable<any> {
    const params = Obj.toUrlParams({
      ...this.defaultParams,
      seoUrl,
    });

    return this.http.get(`${urls.filtroImmobiliCollection()}?${params}`);
  }

  public getAreas(city: string = ''): Observable<any> {
    const p = {
      ...this.defaultParams,
    };

    if (city.length > 0) {
      const cityProp = 'city';
      p[cityProp] = city;
    }

    const params = Obj.toUrlParams(p);

    const url = `${urls.areas()}?${params}`;

    return this.http.get(url);
  }

  public getTwoHierarchicalGroupFields(
    fieldA: string,
    fieldB: string
  ): Observable<any> {
    const params = Obj.toUrlParams({ ...this.defaultParams });

    // const url = entityCollectionTwoFields

    return this.http.get(urls.cityAndArea());
  }

  public getStaticJsonFile(fileName: string): Observable<any> {
    const url = urls.staticJson(fileName);
    return this.http.get(url);
  }

  // public getDynamicPresetsUrlsList(): Observable<any> {
  //   return this.http.get(this.urlPresetDynamicUrlsList);
  // }

  // public getPresetByUrl(type: string, city: string): Observable<any> {
  //   return this.http.get(this.urlPresetFind + type + '/' + city + '/');
  // }

  /* POST requests */

  public postContactForm(data: any): Observable<any> {
    const url = `${this.urlContactForm}`;
    return this.http.post(url, data);
  }

  public postSimpleContactForm(data: any): Observable<any> {
    const url = `${this.urlSimpleContactForm}`;
    return this.http.post(url, data);
  }

  // public test() {
  //   return 'injector';
  // }
}
