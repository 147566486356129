import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class PropertyActions {
  static SET_PROPERTY = 'SET_PROPERTY';
  static SET_PROPERTY_ASSETS = 'SET_PROPERTY_ASSETS';

  static setProperty(property: any): AnyAction {
    return {
      type: PropertyActions.SET_PROPERTY,
      payload: property,
    };
  }

  static setPropertyAssets(
    assets: { id: number; file_name: string }[]
  ): AnyAction {
    return {
      type: PropertyActions.SET_PROPERTY_ASSETS,
      payload: assets,
    };
  }
}
