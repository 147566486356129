import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(arr: Array<any>, prop: any, reverse: boolean = false): any {
    if (typeof arr === undefined || arr === null || arr.length === 0) {
      return [];
    }

    const m = reverse ? -1 : 1;

    const res = arr.sort((a: any, b: any): number => {
      const x = a[prop];

      const y = b[prop];

      return x === y ? 0 : x < y ? -1 * m : 1 * m;
    });

    return res;
  }
}
