import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class ClientActions {
  static SET = 'SET';
  static SET_HOTJAR = 'SET_HOTJAR';
  static SET_USER = 'SET_USER';

  static setHotjar(hotjar: any): AnyAction {
    return {
      type: ClientActions.SET_HOTJAR,
      payload: hotjar
    };
  }

  static setUser(user: any): AnyAction {
    return {
      type: ClientActions.SET_USER,
      payload: user
    };
  }

  static set(client: any): AnyAction {
    return {
      type: ClientActions.SET,
      payload: client
    };
  }
}
