import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentPipe } from 'src/app/pipe/static-content.pipe';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor() {}

  public toggle(key: string) {
    return StaticContentPipe.getFeatures(key);
  }
}
