import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IAppState } from '../../interface/app-state.interface';
import { NgRedux, select } from '@angular-redux/store';
import { InfoActions } from '../../module/store/info/info.action';
import { server } from '../../decorator/server.decorator';
import { DataService } from '../../data.service';
import { Observable } from 'rxjs';

export interface IGoogleReview {
  authorName: string;
  authorUrl: string;
  language: string;
  profilePhotoUrl: string;
  rating: number;
  relativeTimeDescription: string;
  text: string;
  time: number;
  photoBase64: string;
}

@Component({
  selector: 'app-google-review-list',
  templateUrl: './google-review-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleReviewListComponent implements OnInit {
  @select(['info', 'reviews']) public readonly reviews!: Observable<
    IGoogleReview[]
  >;

  constructor(private store: NgRedux<IAppState>, private service: DataService) {
    this.setReviews();
  }

  ngOnInit() {}

  @server
  public setReviews() {
    this.service
      .getStaticJsonFile('google-reviews')
      .toPromise()
      .then((reviews) => {
        this.store.dispatch(
          InfoActions.setReviews(reviews.sort(() => 0.5 - Math.random()))
        );
      })
      .catch((err) => {
        console.log(err);
        InfoActions.setReviews([]);
      });
  }

  public getStars(rating: number): { active: boolean }[] {
    return Array(5)
      .fill(false)
      .map((v, i: number) => ({ active: rating > i }));
  }

  // public getSrc(src: string): string {
  //   return (typeof window !== 'undefined')
  //     ? src
  //     : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88B8AAsUB4ZtvXtIAAAAASUVORK5CYII=';
  // }
}
