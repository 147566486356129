<article class="sc-property">
  <div class="sc-property__main">
    <app-gallery
      style="position: relative"
      [id]="(immobile | async)?.id"
      [assets]="(immobile | async)?.assets | sortBy: 'sort_order':false"
      [resizedPresetName]="'w1500'"
      [detailsEnabled]="false"
      [property]="immobile | async"
    ></app-gallery>

    <div class="sc-property__sidelabel">
      <mat-card>
        <div class="sc-property__sidelabel__tx-group">
          <h1>{{ (immobile | async)?.metaTitle }}</h1>
          <h2>
            Casa in vendita a {{ (immobile | async)?.city }},
            {{ (immobile | async)?.area }}
          </h2>
        </div>
        <div class="sc-property__rif">
          <mat-card class="no--padding">
            <h5 class="sc-property__sidelabel__ref-tag">
              Rif. {{ (immobile | async)?.refId }}
            </h5>
          </mat-card>
        </div>
      </mat-card>

      <button
        mat-mini-fab
        (click)="toggleGalleryFullScreen()"
        class="sc-property__galleryFullScreenToggle mat-full-screen"
        matTooltip="{{ fsLabel }}"
        matTooltipPosition="below"
      >
        <mat-icon>{{
          store.getState().config?.galleryFullScreen ? "close" : "fullscreen"
        }}</mat-icon>
      </button>
      <!--<pre style="position: absolute; background-color: #555555;color: #25D366; top:0;z-index: 10000000000000;">{{ store.getState().config | json }}</pre>-->
    </div>

    <div *ngIf="(immobile | async)?.sold" class="sc-property__sold">
      <div class="sc-property__sold__sign">venduto</div>
    </div>

    <div
      class="sc-property__main__data"
      *ngIf="toggle('pages.property.property_features')"
    >
      <section class="row sc-property__features">
        <div
          class="sc-property-feature"
          *ngFor="let feature of highlightedFeatures"
        >
          <i class="sc-property-feature__icon" [ngClass]="[feature.icon]"></i>
          <div class="sc-property-feature__text">
            <span class="sc-property-feature__text__label">{{
              feature.label
            }}</span>
            <span
              class="sc-property-feature__text__value"
              [innerHTML]="
                renderFeatureValue(feature) + ' ' + feature.measure | safeHtml
              "
            ></span>
          </div>
        </div>
      </section>
    </div>
  </div>

  <section class="sc-property__titlerow">
    <div
      class="sc-property__titlerow__element sc-property__titlerow__element-cta"
    >
      <a
        [appNgAnalyticsSendEvent]="['Click', 'property.openContactForm']"
        class="sc-property__titlerow__element__contact mat-cta-calendar"
        mat-raised-button
        color="warn"
        (click)="showContactForm()"
      >
        <div class="sc-box__bt--main">
          <mat-icon>today</mat-icon>
          <span> {{ "PROPERTY.CTA_CONTATTACI.TEXT" | staticContent }} </span>
        </div>
      </a>

      <a
        [appNgAnalyticsSendEvent]="['Click', 'property.clickPhoneNumber']"
        class="sc-property__titlerow__element__contact mat-contattaci"
        mat-raised-button
        href="{{ 'PROPERTY.CTA_CONTATTACI.URL' | staticContent }}"
      >
        <div class="sc-box__bt--main">
          <mat-icon>phone</mat-icon>
          <span
            >Contattaci al {{ "PROPERTY.CTA_CONTATTACI.PHONE" | staticContent }}
          </span>
        </div>
      </a>
      <!-- todo: entire bt turn into component-->
    </div>

    <div
      class="sc-property__titlerow__element sc-property__titlerow__element-info"
    >
      <div class="sc-property__titlerow__element-info__text">
        <h3>{{ (immobile | async)?.metaTitle | ucFirst }}</h3>
        <h4>
          Proprietà in
          <span *ngIf="!toggle('pages.property.dynamic_status')">
            vendita
          </span>
          <span *ngIf="toggle('pages.property.dynamic_status')">{{
            (immobile | async)?.immobileCategoria.label?.toLowerCase()
          }}</span>
          a: {{ (immobile | async)?.city | ucFirst }},
          {{ (immobile | async)?.area }}
        </h4>
      </div>
      <div class="sc-property__titlerow__element-info__details">
        <div class="sc-property__titlerow__element-info__details__left">
          <!--<div *ngIf="!(immobile | async)?.price_reserved">-->
          <!--&euro; {{ (immobile | async)?.price | currencyFormat }} <br />-->
          <!--Spese condominiali: &euro; {{ getServiceCharge() }}-->
          <!--</div>-->
          <!--<div *ngIf="(immobile | async)?.price_reserved">-->
          <!--Trattativa riservata-->
          <!--</div>-->
          <!---->

          <span *ngIf="!(immobile | async)?.priceReserved">
            <i class="icon-icons_euro_symbol"></i>
            {{ (immobile | async)?.price | currencyFormat }}
          </span>
          <span
            *ngIf="(immobile | async)?.priceReserved"
            class="sc-property__titlerow__element-info__details__left-reserved"
          >
            Trattativa riservata
          </span>

          <span>
            <i class="icon-icons_mq"></i>
            {{ (immobile | async)?.surfaceSqm }} mq
          </span>
        </div>
        <div class="sc-property__titlerow__element-info__details__right">
          <span>RIF. {{ (immobile | async)?.refId }}</span>
        </div>
      </div>
    </div>
  </section>

  <div class="row sc-property__body">
    <div
      [ngClass]="getContent('SHARED.PROPERTY_SEGMENT.BODY_COLUMN_WIDTH_LEFT')"
    >
      <section class="sc-property__description text-justify">
        <h3>{{ (immobile | async)?.metaTitle | ucFirst }}</h3>
        <h4>
          Proprietà in vendita a: {{ (immobile | async)?.city | ucFirst }},
          {{ (immobile | async)?.area }}
        </h4>
        <div class="sc-property__description__body">
          {{ (immobile | async)?.description | ucFirst }}
        </div>
      </section>

      <div class="row my-4">
        <div class="col-md-6">
          <!--TODO convertire box in componente-->
          <section class="sc-property__infoblock sc-property__infoblock-price">
            <label>Prezzo</label>
            <div *ngIf="!(immobile | async)?.priceReserved">
              &euro; {{ (immobile | async)?.price | currencyFormat }} <br />
              Spese condominiali: &euro; {{ getServiceCharge() }}
            </div>
            <div *ngIf="(immobile | async)?.priceReserved">
              Trattativa riservata
            </div>
          </section>

          <section class="sc-property__infoblock">
            <label>Metratura</label>
            interno: <span [innerHtml]="getSqm('surfaceSqm')"></span> <br />
            terrazzo: <span [innerHtml]="getSqm('terraceSqm')"></span> <br />
            cantina: <span [innerHtml]="getSqm('cellarSqm')"></span> <br />
            giardino: <span [innerHtml]="getSqm('gardenSqm')"></span>
          </section>

          <section class="sc-property__infoblock">
            <label>Stanze</label>
            Camere da letto: {{ (immobile | async)?.bedroomsCount }} <br />
            Bagni: {{ (immobile | async)?.bathroomsCount }}
          </section>

          <section class="sc-property__infoblock">
            <label>Riscaldamento</label>
            {{ (immobile | async)?.immobileHeathing?.name || "-" }}
          </section>
        </div>
        <div class="col-md-6">
          <section class="sc-property__infoblock">
            <label>Condizioni dell'immobile</label>
            {{ (immobile | async)?.immobileCondition?.name || "-" }}
          </section>

          <section class="sc-property__infoblock">
            <app-features [data]="featuresList"></app-features>
          </section>

          <!-- --------- ENERGY EFFICENCY --------- -->
          <!-- <section class="sc-property__infoblock ">
            <label>Classe di efficienza</label>
            <app-property-energy-efficiency
              *ngIf="(immobile | async)?.apeCertification"
              [value]="(immobile | async)?.apeCertification"
            ></app-property-energy-efficiency>

            <div
              *ngIf="!(immobile | async)?.apeCertification"
              class="sc-property__noinfoblock"
            >
              <mat-icon class="mr-2">schedule</mat-icon>
              <span class="icon-fb_efficiency mr-1"></span>
              <span>Certificazione in fase di realizzazione</span>
            </div>
          </section> -->
          <!-- --------- ENERGY EFFICENCY --------- -->
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-12">
          <!-- --------- ENERGY EFFICENCY --------- -->
          <section class="sc-property__infoblock">
            <label>Classe di efficienza</label>
            <app-property-energy-efficiency
              *ngIf="(immobile | async)?.apeCertification"
              [value]="(immobile | async)?.apeCertification"
              [isNewAPE]="!(immobile | async)?.isOldApe"
            ></app-property-energy-efficiency>

            <div
              *ngIf="!(immobile | async)?.apeCertification"
              class="sc-property__noinfoblock"
            >
              <!--<mat-icon class="mr-2">schedule</mat-icon>-->
              <span class="icon-fb_efficiency mr-1"></span>
              <span>Certificazione in fase di realizzazione</span>
            </div>
          </section>
          <!-- --------- ENERGY EFFICENCY --------- -->
        </div>
      </div>
    </div>
    <div
      [ngClass]="getContent('SHARED.PROPERTY_SEGMENT.BODY_COLUMN_WIDTH_RIGHT')"
    >
      <app-contact-form-inline
        [propertyId]="(immobile | async)?.id"
        [propertyTitle]="(immobile | async)?.metaTitle"
        [propertySeoUrl]="(immobile | async)?.seoUrl"
        [propertyRef]="(immobile | async)?.refId"
        (formIsVisibleEmitter)="formVisibilityChange($event)"
        class="sc-property__inline-contact-form"
        *ngIf="toggle('pages.property.upper_inline_contact_form')"
      ></app-contact-form-inline>

      <section class="sc-property__infoblock">
        <div class="sc-property__mapbox" *ngIf="(immobile | async)?.position">
          <app-map [data]="(immobile | async)?.position"></app-map>
        </div>
      </section>

      <section class="sc-property__infoblock">
        <app-contact-form-inline
          [propertyId]="(immobile | async)?.id"
          [propertyTitle]="(immobile | async)?.metaTitle"
          [propertySeoUrl]="(immobile | async)?.seoUrl"
          [propertyRef]="(immobile | async)?.refId"
          (formIsVisibleEmitter)="formVisibilityChange($event)"
          class="sc-property__inline-contact-form"
          *ngIf="toggle('pages.property.lower_inline_contact_form')"
        ></app-contact-form-inline>
      </section>

      <section
        class="sc-property__floorplan"
        *ngIf="(immobile | async)?.floorplan"
      >
        <mat-card>
          <img
            [src]="getImgPath((immobile | async)?.floorplan, 'w800')"
            [alt]="imgNameToAlt((immobile | async)?.floorplan)"
          />
        </mat-card>
      </section>
    </div>
  </div>

  <section class="sc-property__titlerow">
    <div
      class="sc-property__titlerow__element sc-property__titlerow__element-cta"
    >
      <a
        [appNgAnalyticsSendEvent]="['Click', 'property.openContactForm']"
        class="sc-property__titlerow__element__contact mat-cta-calendar"
        mat-raised-button
        color="warn"
        (click)="showContactForm()"
      >
        <div class="sc-box__bt--main">
          <mat-icon>today</mat-icon>
          <span> {{ "PROPERTY.CTA_CONTATTACI.TEXT" | staticContent }} </span>
        </div>
      </a>

      <a
        [appNgAnalyticsSendEvent]="['Click', 'property.clickPhoneNumber']"
        class="sc-property__titlerow__element__contact mat-contattaci"
        mat-raised-button
        href="{{ 'PROPERTY.CTA_CONTATTACI.URL' | staticContent }}"
      >
        <div class="sc-box__bt--main">
          <mat-icon>phone</mat-icon>
          <span
            >Contattaci al {{ "PROPERTY.CTA_CONTATTACI.PHONE" | staticContent }}
          </span>
        </div>
      </a>

      <!--<div class="sc-property__titlerow__element__whatsapp">-->
      <!--<i class="fa fa-2x fa-whatsapp"></i>-->
      <!--</div>-->
    </div>
  </section>

  <ng-container *ngIf="toggle('pages.property.print_button')">
    <div class="text-center p-3">
      <button
        class="sc-button mat-stampa"
        mat-raised-button
        color="warn"
        (click)="doPrint()"
      >
        <mat-icon>print</mat-icon>
        <span>STAMPA</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="toggle('pages.property.print_button')">
    <section class="sc-property__infoblock">
      <app-share></app-share>
    </section>
  </ng-container>
</article>

<app-contact-form
  [propertyId]="(immobile | async)?.id"
  [propertyTitle]="(immobile | async)?.metaTitle"
  [propertySeoUrl]="(immobile | async)?.seoUrl"
  [propertyRef]="(immobile | async)?.refId"
  *ngIf="visibility.contactForm"
  (formIsVisibleEmitter)="formVisibilityChange($event)"
></app-contact-form>
