import { Reducer } from 'redux';

const initialState = {};

export const contentReducer: Reducer<any> = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case 'SET_CONTENT':
      return action.payload;
  }

  return state;
};
