import { ISimpleMap } from '../interface/simple-map.interface';
// import * as entries from 'object.entries';

export class Obj {
  /**
   * @description
   * Given an object and flat keys translation map
   * returns the object with the translated keys.
   */

  public static mapKeys = <T, U>(object: T, map: ISimpleMap): U =>
    Object.keys(object as any).reduce(
      (a: any, p: string) => ({
        ...a,
        [map[p] || p]: (object as any)[p],
      }),
      {}
    );

  /**
   * @description
   * Given a flat object returns a url parameters string.
   */

  public static toUrlParams = (parameters: ISimpleMap): string =>
    Object.entries(
      // todo: Object.assign polyfill // phantomjs
      Object.keys(parameters).reduce(
        (a, p) => ({
          ...a,
          [encodeURIComponent(p)]: encodeURIComponent(parameters[p]),
        }),
        {}
      )
    )
      .map((i) => i.join('='))
      .join('&');

  /**
   * @description
   * Unsets empty keys in a given object.
   */

  public static unsetEmpty(object: any): any {
    return Object.keys(object).reduce((a, c) => {
      if (
        (typeof object[c] !== 'number' &&
          typeof object[c] !== 'undefined' &&
          object[c] !== null &&
          object[c] !== '') ||
        (typeof object[c] === 'number' && !isNaN(object[c]))
      ) {
        (a as any)[c] = object[c];
      }

      return a;
    }, {});
  }

  /**
   * @description Access and init object props recursively.
   */

  public static recursiveProps(
    object: any,
    props: string[],
    initNotSetProps = false,
    value: any = null
  ): any {
    return props.reduce(
      (acc: any, prop: string, i: number, arr: string[]): any => {
        if (
          initNotSetProps &&
          (typeof acc[prop] === 'undefined' || acc[prop] === null)
        ) {
          acc[prop] = {};
        }

        if (i === arr.length - 1 && value !== null) {
          acc[prop] = value; // todo: allow merging !??
        }

        return acc[prop];
      },
      object
    );
  }

  public static getPathValue(object: any, path: string[]): any {
    return path.reduce((acc: any, cur: string, i: number, arr: any[]): any => {
      const propExists =
        typeof acc !== 'undefined' &&
        acc !== null &&
        typeof acc[cur] !== 'undefined';

      if (!propExists) {
        arr = arr.splice(0, i);
      }

      return propExists ? acc[cur] : null;
    }, object);
  }
}
