<mat-card class="sc-map">
  <div class="sc-map__image" [style.background-image]="'url('+url+')'"></div>
  <div class="sc-map__image__pointer"></div>



    <div class="sc-map__image__button">
      <button mat-mini-fab color="accent" (click)="changeType()"><i class="material-icons">layers</i></button>
    </div>
    <div class="sc-map__image__button">
      <button class="sc-map__button" mat-mini-fab color="accent" (click)="zoomPlus()">+</button>
    </div>
    <div class="sc-map__image__button">
      <button class="sc-map__button" mat-mini-fab color="accent" (click)="zoomMinus()">-</button>
    </div>

</mat-card>

