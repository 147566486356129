<div class="sc-header-info">
  <div
    *ngFor="let param of 'SHARED.HEADER.CONTACT' | staticContent"
    class="sc-header-info__item-wrapper"
  >
    <div
      class="sc-header-info__item"
      [ngClass]="param.mobile ? '' : 'hideOnMobile'"
    >
      <i class="material-icons">{{ param.icon }}</i>
      <span class="sc-header-info__item__text-group">
        <span class="sc-header-info__item__label">{{ param.label }}</span>

        <span
          *ngIf="param.label && param.value"
          class="sc-header-info__item__label"
          >:</span
        >
        <!--<span [outerHTML]="param.value | addHref : param.href"></span>-->

        <a
          class="sc-header-info__item__value"
          *ngIf="param.href"
          [href]="param.href"
          >{{ param.value }}
        </a>
        <span class="sc-header-info__item__value" *ngIf="!param.href"
          >{{ param.value }}
        </span>
      </span>
    </div>
  </div>
</div>

<div class="sc-header">
  <nav class="navbar navbar-expand-md navbar-dark">
    <a
      class="navbar-brand sc-logo"
      href="#"
      [appNgAnalyticsSendEvent]="[
        'Click',
        'nav.bt0.logo.home',
        'SHARED.HEADER.AGENCY_NAME' | staticContent
      ]"
    >
      <img
        src="{{ 'SHARED.HEADER.PATH_LOGO' | staticContent }}"
        alt="{{ 'SHARED.HEADER.AGENCY_NAME' | staticContent }}"
      />
      <div class="sc-logo__tx-group">
        <span class="sc-logo__tx">
          {{ "SHARED.HEADER.TXT_LOGO" | staticContent }}
        </span>
        <span class="sc-logo__tx-small">{{
          "SHARED.HEADER.TXT_LOGO_SMALL" | staticContent
        }}</span>
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto">
        <ng-container *ngFor="let item of 'SHARED.HEADER.MENU' | staticContent">
          <li
            class="nav-item sc-nav-item"
            [ngClass]="{ active: isActive(item.url) }"
            *ngIf="!item.disable"
          >
            <a
              target="{{ item.target }}"
              class="nav-link sc-nav-link"
              href="{{ render(item.url) }}"
              (click)="item?.logout ? logout() : null"
              [appNgAnalyticsSendEvent]="['Click', 'nav.bt1.home', item.name]"
            >
              {{ render(item.name) }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</div>
