import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class PropertiesActions {
  static SET_PROPERTIES = 'SET_PROPERTIES';
  static DELETE_PROPERTY = 'DELETE_PROPERTY';

  static setProperties(properties: any): AnyAction {
    return {
      type: PropertiesActions.SET_PROPERTIES,
      payload: properties,
    };
  }

  static deleteProperty(propertyId: number): AnyAction {
    return {
      type: PropertiesActions.DELETE_PROPERTY,
      payload: propertyId,
    };
  }
}
