import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProperty } from '../../interface/property.interface';
import { env } from '../../lib/config.lib';
import { AssetLib } from '../../lib/assets.lib';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticContentPipe } from '../../pipe/static-content.pipe';
import { IAssets } from '../../interface/assets.interface';
// import { client } from "../../decorator/client.decorator";
// import { DataService } from "../../data.service";

@Component({
  selector: '[app-preview]',
  templateUrl: './preview.component.html',
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewComponent extends AssetLib {
  @Input() property!: IProperty;
  @Input() index!: number;

  public env = env;

  public constructor(public router: Router, public route: ActivatedRoute) {
    super();
  }

  public getFirstAsset(assets: IAssets[]): any {
    // todo: interface `IAssets` should be renamed
    return assets.find((asset: IAssets) => asset.sortOrder === 0) || assets[0];
  }

  public ucFirst(text: string): string {
    return text.charAt(0).toUpperCase() + text.substr(1);
  }

  public getArea(): string {
    return typeof this.property.area !== 'undefined' &&
      this.property.area !== null &&
      this.property.area !== ''
      ? this.property.area
      : '';
  }

  domainPath(): string {
    const staticRoutes = StaticContentPipe.getContent('SHARED.HEADER.MENU');

    return staticRoutes[1].url + '/';
  }
}
