import { Component, OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { CookiesHelper } from '../../lib/cookies-helper.lib';
// import { getCookie, setCookie, expireCookie } from 'redux-cookie';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
})
export class CookieComponent implements OnInit {
  public cookieAccepted!: boolean;

  constructor(
    public store: NgRedux<IAppState>,
    public cookieService: CookiesHelper
  ) {}

  ngOnInit() {
    this.cookieAccepted = this.cookieService.policyAccepted;
  }

  public accetto(evt: Event) {
    evt.preventDefault();

    this.cookieService.setPolicyAccepted();
    this.cookieAccepted = this.cookieService.policyAccepted;

    location.reload();
  }
}
