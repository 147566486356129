import { Component, Optional, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { server } from '../../decorator/server.decorator';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { PageActions } from '../../module/store/page/page.action';
import { client } from '../../decorator/client.decorator';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { IPageMeta } from '../../interface/pagemeta';
import { MetaService } from '../../meta.service';
import { StaticContentPipe } from '../../pipe/static-content.pipe';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent {
  @select(['page']) public page!: Observable<any>;
  @select(['config', 'noMatchFound']) public noMatchFound!: Observable<boolean>;

  // public page: IPage;

  public autoUnsubscribe = new AutoUnsubscribe();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DataService,
    protected store: NgRedux<IAppState>,
    private meta: MetaService,

    @Optional() @Inject(RESPONSE) private response: any
  ) {
    this.checkLocationClient();

    const subscription = this.route.params.subscribe((params: Params) => {
      const seoUrlProp = 'seoUrl';
      const seoUrl = params[seoUrlProp];

      this.getPage(seoUrl);
    });

    this.autoUnsubscribe.add(subscription);
  }

  @client
  public checkLocationClient() {
    this.noMatchFound.subscribe((v) => {
      if (v === true) {
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    });
  }

  @server
  public getPage(seoUrl: string) {
    const subscription = this.service.getPage(seoUrl).subscribe(
      (data: any) => {
        if (data.data.length === 0) {
          this.router.navigate(['/404']);
          return;
        }

        const page = data.data[0];

        this.store.dispatch(PageActions.setPage(page));

        this.setMeta();
      },
      (err) => console.log(err)
    );

    this.autoUnsubscribe.add(subscription);
  }

  public setMeta(): void {
    const staticContent = new StaticContentPipe();

    const logoSeo = staticContent.transform('SHARED.HEADER.PATH_LOGO_SEO');

    const metaObj: IPageMeta = {
      author: StaticContentPipe.getEnv('paths.baseUrl'),
      keywords: 'comprare affittare casa',
      description: this.store.getState().page.metaDescription,
      siteName: StaticContentPipe.getEnv('paths.baseUrl'),
      title: this.store.getState().page.metaTitle,
      image: StaticContentPipe.getEnv('paths.baseUrl') + '/' + logoSeo,
      url: StaticContentPipe.getEnv('paths.baseUrl'),
    };

    this.meta.setMeta(metaObj);
  }
}
