import { IEfficiencyRange } from '../app/component/property-energy-efficiency/property-energy-efficiency.component';

export class HbsHelpers {
  public static ucFirst = (text: string) =>
    typeof text !== 'undefined' && text !== null && text.length > 0
      ? text.charAt(0).toUpperCase() + text.substr(1)
      : '';

  public static capitalize = (text: string) =>
    text
      .split(' ')
      .map((v) => HbsHelpers.ucFirst(v.toLowerCase()))
      .join(' ');

  public static check() {
    return '-';
  }

  public static getPropInObj = (o: any, p: string) => o[p];

  public static toJson = (data: any) => JSON.stringify(data);

  public static renderCheckmark = (d: boolean): string =>
    d
      ? '<span class="checkMark checkMark--v material-icons">check_circle</span>'
      : '<span class="checkMark checkMark--x material-icons">highlight_off</span>';

  public static zeroIfNull = (v: any) => (v ? v : '0');

  public static parseJson = (data: any) =>
    data === null ? {} : JSON.parse(data);

  public static getPosMarkers = (d: { lat: number; lng: number }) =>
    d ? `${d.lat},${d.lng}` : '';

  public static effRange = (data: IEfficiencyRange[], value: number) =>
    data.find((range: IEfficiencyRange) => range.value >= value);

  // public static efrData = efficiencyRangeData;

  public static isEqual = (a: any, b: any) => a === b;

  public static isSet = (v: any) => typeof v !== 'undefined' && v !== null;

  public static isNotEmpty = (v: any) =>
    typeof v !== 'undefined' &&
    v !== null &&
    v !== 0 &&
    v !== '' &&
    v?.length !== 0; // &&
  // v !== {};

  public static isEmpty = (v: any) =>
    !(
      (
        typeof v !== 'undefined' &&
        v !== null &&
        v !== 0 &&
        v !== '' &&
        v?.length !== 0
      ) // &&
      // v !== {}
    );

  public static defaultValue(v: any, dv: any) {
    return HbsHelpers.isEmpty(v) ? dv : v;
  }

  public static formatCurrency = (
    amount: any,
    decimalCount = 2,
    decimal = '.',
    thousands = ','
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = Number(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - Number(i))
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  };
}
