<div class="sc-home">
  <!--<div class="sc-home__title">-->
  <!--</div>-->

  <!--BOX CENTRALE SCOPRI LA TUA NUOVA CASA E RICERCA-->
  <section class="sc-home__box-container">
    <app-box></app-box>
  </section>

  <!--IMMOBILI IN EVIDENZA-->
  <section class="sc-home__evidenza-container">
    <app-property-segment
      [key]="'featuredHome'"
      [value]="'true'"
    ></app-property-segment>
  </section>
</div>
