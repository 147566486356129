import { Reducer } from 'redux';

export type UserState = {
  id: string;
  name: string;
  role: string;
  token: string;
} | null;
const initialState: UserState = null;

export const userReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...action.payload,
      };
  }

  return state;
};
