import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturesComponent implements OnInit {
  @Input() data!: { key: string; label: string; value: boolean }[];

  constructor() {}

  ngOnInit() {}

  public computeValue(data: any): string {
    return !!data.value ? 'check_circle_outline' : 'highlight_off';
  }

  public getIconColor(value: any) {
    return !!value ? '#7a7' : '#a76';
  }
}
