<section
  class="sc-contact-inline"
  *ngIf="toggle('pages.property.inline_contact_form.layout1')"
>
  <!--<div class="sc-contact-inline__form">-->
  <mat-card>
    <div class="sc-contact-inline-content">
      <div class="sc-contact-inline-content__form">
        <form #f="ngForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
          <mat-form-field>
            <input
              matInput
              class="mat-form-field-label-contact"
              placeholder="Nome *"
              value=""
              formControlName="name"
            />
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="email"
              class="mat-form-field-label-contact"
              placeholder="Email *"
              value=""
              formControlName="email"
            />
          </mat-form-field>

          <input
            matInput
            type="text"
            class="mat-form-field-label-contact"
            placeholder=""
            value=""
            formControlName="middleName"
            [hidden]="true"
          />

          <input
            matInput
            type="text"
            class="mat-form-field-label-contact"
            placeholder=""
            value=""
            formControlName="fax"
            [hidden]="true"
          />

          <mat-form-field>
            <textarea
              matInput
              class="mat-form-field-label-contact"
              placeholder="messaggio*"
              value="Desidero richiedere maggiori informazioni in merito all’immobile RIF.{{
                propertyRef
              }}"
              formControlName="message"
              rows="5"
            >
            </textarea>
          </mat-form-field>

          <ng-container
            *ngIf="
              toggle('pages.property.inline_contact_form.privacy_checkbox')
            "
          >
            <mat-checkbox
              placeholder="privacy"
              formControlName="privacy"
              style="width: 100%"
            >
              consenso
              <a
                href="{{ 'SHARED.FOOTER.PRIVACY_POLICY_URL' | staticContent }}"
                target="_blank"
                >privacy</a
              >
            </mat-checkbox>
          </ng-container>

          <!--<app-recaptcha [sitekey]="'6LeihmwUAAAAABjQOx37lHhyL-g-uL5ThF-P4kJS'" formControlName="recaptcha"></app-recaptcha>-->

          <div
            class="sc-sc-contact-inline-content__contactbt--submit text-center"
          >
            <button
              type="submit"
              class="btn sc-button my-2 mat-sc-primary"
              [disabled]="!submitEnabled()"
              mat-raised-button
              [appNgAnalyticsSendEvent]="[
                'Click',
                'property.form.contact.submit'
              ]"
            >
              Invia messaggio
            </button>
          </div>
        </form>

        <ng-container
          *ngIf="toggle('pages.property.inline_contact_form.whatsapp_button')"
        >
          <div
            *ngIf="isMobile || true"
            class="text-center mt-3 my-2 text-center"
          >
            <div class="sc-sc-contact-inline-content__contactdivider">
              <strong
                class="sc-sc-contact-inline-content__contactdivider--title"
                >oppure</strong
              >
            </div>

            <a
              class="btn sc-button mat-whatsapp mt-2"
              type="button"
              mat-raised-button
              [href]="whatsAppHref()"
              target="_blank"
              [appNgAnalyticsSendEvent]="[
                'Click',
                'property.form.contact.whatsappBt'
              ]"
            >
              <i class="fa fa-2x fa-whatsapp mr-2"></i>
              <span>Contattami su Whatsapp</span>
            </a>
          </div>
        </ng-container>
      </div>

      <div class="sc-contact-inline-content__contact">
        <img
          src="{{ 'SHARED.HEADER.PATH_LOGO_COLOR' | staticContent }}"
          alt="{{ 'SHARED.HEADER.AGENCY_NAME' | staticContent }}"
        />

        <div class="sc-contact-inline-content__contact__label">
          {{ "SHARED.PROPERTY_SEGMENT.CONTACT_FORM_LABEL" | staticContent }}
        </div>

        <span
          class="sc-contact-inline-content__contact__item"
          *ngFor="let param of 'SHARED.HEADER.CONTACT' | staticContent"
        >
          <i
            class="material-icons"
            [ngClass]="param.mobile ? '' : 'hideOnMobile'"
            >{{ param.icon }}
          </i>
          <span
            class="sc-contact-inline-content__contact__item__text-group"
            [ngClass]="param.mobile ? '' : 'hideOnMobile'"
          >
            <!--<span class="sc-contact-inline-content__contact__item__label" [ngClass]="param.mobile ? '' : 'hideOnMobile'">-->
            <!--{{ param.label }}:-->
            <!--</span>-->
            <!--<span [outerHTML]="param.value | addHref : param.href"></span>-->

            <a
              class="sc-contact-inline-content__contact__item__value"
              *ngIf="param.href"
              [href]="param.href"
              >{{ param.value }}
            </a>
            <span
              class="sc-contact-inline-content__contact__item__value"
              *ngIf="!param.href"
              >{{ param.value }}
            </span>
          </span>
        </span>
      </div>
    </div>
  </mat-card>
  <!--</div>-->
</section>

<!-- LAYOUT 2  -->
<section
  class="sc-contact-inline"
  *ngIf="toggle('pages.property.inline_contact_form.layout2')"
>
  <!--<div class="sc-contact-inline__form">-->

  <div class="sc-contact-inline-content">
    <div class="sc-contact-inline-content__form">
      <mat-card>
        <form #f="ngForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
          <mat-form-field>
            <input
              matInput
              class="mat-form-field-label-contact"
              placeholder="Nome *"
              value=""
              formControlName="name"
            />
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="email"
              class="mat-form-field-label-contact"
              placeholder="Email *"
              value=""
              formControlName="email"
            />
          </mat-form-field>

          <input
            matInput
            type="text"
            class="mat-form-field-label-contact"
            placeholder=""
            value=""
            formControlName="middleName"
            [hidden]="true"
          />

          <input
            matInput
            type="text"
            class="mat-form-field-label-contact"
            placeholder=""
            value=""
            formControlName="fax"
            [hidden]="true"
          />

          <mat-form-field>
            <textarea
              matInput
              class="mat-form-field-label-contact"
              placeholder="messaggio*"
              value="Desidero richiedere maggiori informazioni in merito all’immobile RIF.{{
                propertyRef
              }}"
              formControlName="message"
              rows="5"
            >
            </textarea>
          </mat-form-field>

          <ng-container
            *ngIf="
              toggle('pages.property.inline_contact_form.privacy_checkbox')
            "
          >
            <mat-checkbox
              placeholder="privacy"
              formControlName="privacy"
              style="width: 100%"
            >
              consenso
              <a
                href="{{ 'SHARED.FOOTER.PRIVACY_POLICY_URL' | staticContent }}"
                target="_blank"
                >privacy</a
              >
            </mat-checkbox>
          </ng-container>

          <!--<app-recaptcha [sitekey]="'6LeihmwUAAAAABjQOx37lHhyL-g-uL5ThF-P4kJS'" formControlName="recaptcha"></app-recaptcha>-->

          <div
            class="sc-sc-contact-inline-content__contactbt--submit text-center"
          >
            <button
              type="submit"
              class="btn sc-button my-2 mat-sc-primary"
              [disabled]="!submitEnabled()"
              mat-raised-button
              [appNgAnalyticsSendEvent]="[
                'Click',
                'property.form.contact.submit'
              ]"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
              Richiedi info
            </button>
          </div>
        </form>
      </mat-card>

      <ng-container
        *ngIf="toggle('pages.property.inline_contact_form.whatsapp_button')"
      >
        <div *ngIf="isMobile || true" class="text-center mt-3 my-2 text-center">
          <div class="sc-sc-contact-inline-content__contactdivider">
            <strong class="sc-sc-contact-inline-content__contactdivider--title"
              >oppure</strong
            >
          </div>

          <a
            class="btn sc-button mat-whatsapp mt-2"
            type="button"
            mat-raised-button
            [href]="whatsAppHref()"
            target="_blank"
            [appNgAnalyticsSendEvent]="[
              'Click',
              'property.form.contact.whatsappBt'
            ]"
          >
            <i class="fa fa-2x fa-whatsapp mr-2"></i>
            <span>Contattami su Whatsapp</span>
          </a>
        </div>
      </ng-container>
    </div>

    <div class="sc-contact-inline-content__contact">
      <div class="sc-contact-inline-content__contact__topgroup">
        <img
          src="{{ 'SHARED.HEADER.PATH_LOGO_COLOR' | staticContent }}"
          alt="{{ 'SHARED.HEADER.AGENCY_NAME' | staticContent }}"
        />

        <div class="sc-contact-inline-content__contact__topgroup__label">
          {{ "SHARED.PROPERTY_SEGMENT.CONTACT_FORM_LABEL" | staticContent }}
        </div>
      </div>

      <div class="separator">
         
      </div>

      <span
        class="sc-contact-inline-content__contact__item"
        *ngFor="let param of 'SHARED.HEADER.CONTACT' | staticContent"
      >
        <i class="material-icons" [ngClass]="param.mobile ? '' : 'hideOnMobile'"
          >{{ param.icon }}
        </i>
        <span
          class="sc-contact-inline-content__contact__item__text-group"
          [ngClass]="param.mobile ? '' : 'hideOnMobile'"
        >
          <!--<span class="sc-contact-inline-content__contact__item__label" [ngClass]="param.mobile ? '' : 'hideOnMobile'">-->
          <!--{{ param.label }}:-->
          <!--</span>-->
          <!--<span [outerHTML]="param.value | addHref : param.href"></span>-->

          <a
            class="sc-contact-inline-content__contact__item__value"
            *ngIf="param.href"
            [href]="param.href"
            >{{ param.value }}
          </a>
          <span
            class="sc-contact-inline-content__contact__item__value"
            *ngIf="!param.href"
            >{{ param.value }}
          </span>
        </span>
      </span>
    </div>
  </div>

  <!--</div>-->
</section>
