import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute } from '@angular/router';
import { IPageMeta } from '../../interface/pagemeta';
import { MetaService } from '../../meta.service';
import { StaticContentPipe } from '../../pipe/static-content.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(
    private activatedRoute: ActivatedRoute, // fixme: remove?
    private service: DataService, // fixme: remove?
    private meta: MetaService
  ) {
    const staticContent = new StaticContentPipe();
    const agency = staticContent.transform('SHARED.HEADER.AGENCY_NAME');
    const logoSeo = staticContent.transform('SHARED.HEADER.PATH_LOGO_SEO');

    // TODO Set image
    const metaObj: IPageMeta = {
      author: StaticContentPipe.getEnv('paths.baseUrl'),
      title: 'Cerca immobili in vendita a Savona e zone limitrofe',
      description: `Cerca appartamenti, ville, nuove costruzioni in vendita a Savona e zone limitrofe. Contatta il team di ${agency} per supportarti nel tuo prossimo investimento in Liguria.`,
      siteName: StaticContentPipe.getEnv('paths.baseUrl'),
      image: StaticContentPipe.getEnv('paths.baseUrl') + '/' + logoSeo,
      url: StaticContentPipe.getEnv('paths.baseUrl'),
    };

    this.meta.setMeta(metaObj);
  }
}
