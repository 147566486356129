<div class="sc-google-reviews-listwrp">
  <h3>{{ "GOOGLEREVIEW.TITLE" | staticContent }}</h3>

  <h4>{{ "GOOGLEREVIEW.SUBTITLE" | staticContent }}</h4>

  <div class="sc-google-reviews-list">
    <div
      *ngFor="let review of reviews | async"
      class="sc-google-review__wrapper"
    >
      <div class="sc-google-review">
        <div class="sc-google-review__avatar">
          <img
            [src]="review.photoBase64"
            [alt]="review.authorName"
            rel="canonical"
          />

          <div class="sc-google-review__avatar__header">
            <a
              class="sc-google-review__avatar__header__name"
              [href]="review.authorUrl"
              target="_blank"
              >{{ review.authorName }}
            </a>
            <div class="sc-google-review__avatar__header__date">
              {{ review.time * 1000 | date: "HH:mm dd/MM/yy":"+2000" }}
            </div>
          </div>
        </div>

        <div class="sc-google-review__stars">
          <mat-icon
            *ngFor="let star of getStars(review.rating)"
            class="sc-google-review__star"
            [ngClass]="{ 'sc-google-review__star--active': star.active }"
            >star</mat-icon
          >
        </div>

        <div class="sc-google-review__body">{{ review.text }}</div>
      </div>
    </div>
  </div>
</div>
