import {
  Component,
  Input,
  forwardRef,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import { client } from '../../decorator/client.decorator';
import { v4 as uuid } from 'uuid';

let grecaptcha: any;

if (typeof window !== 'undefined') {
  const k = 'grecaptcha';
  grecaptcha = (window as any)[k];
}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RecaptchaComponent),
  multi: true,
};

export const CUSTOM_INPUT_CONTROL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RecaptchaComponent),
  multi: true,
};

@Component({
  selector: 'app-recaptcha',
  template: `
    <div class="sc-recaptcha">
      <div [id]="id"></div>
      <!--<input [(ngModel)]="_innerValue">-->
    </div>
  `,
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR,
    CUSTOM_INPUT_CONTROL_VALIDATOR,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecaptchaComponent implements AfterViewInit, ControlValueAccessor {
  @Input() sitekey!: string;
  @Input('innerValue') _innerValue!: string;

  public id: string;

  propagateChange = (_: any) => {};
  validateFn = (_: any) => {};

  get innerValue() {
    return this._innerValue;
  }

  set innerValue(v) {
    this._innerValue = v;
    this.propagateChange(v);
  }

  writeValue(value: any) {
    if (value) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: (...args: any[]) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {}

  /**
   * todo: extend the methods above (ControllableValueAccessor?)
   */

  validate(c: any) {
    return this.validateFn(c);
  }

  /**
   * -------------------------------------------
   */

  public constructor() {
    this.id = uuid();
  }

  public ngAfterViewInit() {
    this.registerOnReadyCallback();

    this.loadScript();
  }

  @client
  public registerOnReadyCallback() {
    if ((window as any)['rcReady']) {
      this.init();
    } else {
      (window as any)['rcOnloadCallback'] = this.onReadyCallback;
    }
  }

  public onReadyCallback = () => {
    grecaptcha = (window as any)['grecaptcha'];

    this.init();
  };

  @client
  init() {
    if (this.sitekey) {
      const captchaId = grecaptcha.render(this.id, {
        sitekey: this.sitekey, // required
        theme: 'light', // optional
        callback: this.inputCompleteCallback.bind(this), // optional
      });
    }
  }

  @client
  public loadScript() {
    // const head = document.getElementsByTagName('head');

    // const scripts: any[] = Array.from(head[0].getElementsByTagName('script'));

    const scripts: string[] = [];

    scripts.forEach((s: any) => {
      const match = /recaptcha/.test(s.src);

      if (match) {
        return;
      }
    });

    const src = `https://www.google.com/recaptcha/api.js?hl=it&onload=rcOnloadCallback&render=explicit`;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = src;

    // head[0].appendChild(script);
  }

  private inputCompleteCallback(e: any): void {
    this.innerValue = e;
    this.propagateChange(e);
  }
}
