import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeUriComponent',
})
export class DecodeUriComponentPipe implements PipeTransform {
  public transform(uri: string) {
    return decodeURIComponent(uri);
  }
}
