import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, Route } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../interface/app-state.interface';

@Injectable()
export class PageGuard implements CanActivate /*, CanLoad*/ {
  constructor(private router: Router, private store: NgRedux<IAppState>) {}

  // public canLoad(route: Route) {
  //   return false;
  // }

  public canActivate() {
    // todo: http req here (service)

    this.router.navigate(['/404']);

    return true;
  }
}
