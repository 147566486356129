import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class ArticleActions {
  static SET_GLOBAL = 'SET_GLOBAL';
  static SET_ARTICLE = 'SET_ARTICLE';
  static SET_ARTICLES = 'SET_ARTICLES';

  static setGlobal(article: any): AnyAction {
    return {
      type: ArticleActions.SET_GLOBAL,
      payload: article,
    };
  }

  static setArticle(article: any): AnyAction {
    return {
      type: ArticleActions.SET_ARTICLE,
      payload: article,
    };
  }

  static setArticles(articles: any[]): AnyAction {
    return {
      type: ArticleActions.SET_ARTICLES,
      payload: articles,
    };
  }
}
