import { Injectable } from '@angular/core';
import { client } from '../../../../decorator/client.decorator';
import { CookiesHelper } from '../../../../lib/cookies-helper.lib';
// import { domainConfig } from '../../../../config.service';

import galite from 'ga-lite';

@Injectable()
export class AnalyticsGaService {
  constructor(private cookiesService: CookiesHelper) {
    this.init();
  }

  @client
  public init() {
    // window['galite'] = galite;
    // window['ga-disable-UA-122566782-1'] = true;
    // const analyticsId = domainConfig.env.google.analyticsId;
    // if (this.cookiesService.policyAccepted) {
    //   galite('create', analyticsId, 'auto');
    // }
  }

  @client
  public send(...args: any[]) {
    if (this.cookiesService.policyAccepted) {
      galite('send', ...args);
    }
  }
}
