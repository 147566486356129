import { NgRedux, select } from '@angular-redux/store';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { PropertyCategories, PropertyTypes } from 'src/app/types';
import { HbsHelpers } from '../../../amp/helpers.hbs';
import { DataService } from '../../data.service';
import { client } from '../../decorator/client.decorator';
import { server } from '../../decorator/server.decorator';
import { IAppState } from '../../interface/app-state.interface';
import { ICityArea } from '../../interface/city-areas.interface';
import { IPageMeta } from '../../interface/pagemeta';
import { SearchFields } from '../../interface/search-fields';
import { ISimpleMap } from '../../interface/simple-map.interface';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { Obj } from '../../lib/obj.lib';
import { searchToPropertyMap } from '../../map/searchToProperty.map';
import { MetaService } from '../../meta.service';
import { LocationActions } from '../../module/store/location/location.actions';
import { PresetUrlActions } from '../../module/store/preseturl/preset-url.action';
import { PropertiesActions } from '../../module/store/properties/properties.actions';
import { StaticContentPipe } from '../../pipe/static-content.pipe';

// fixme: move to yml
const priceValues: { label: string; value: number | null }[] = [
  { label: '-', value: null },
  { label: '50.000', value: 50000 },
  { label: '100.000', value: 100000 },
  { label: '150.000', value: 150000 },
  { label: '200.000', value: 200000 },
  { label: '250.000', value: 250000 },
  { label: '300.000', value: 300000 },
  { label: '350.000', value: 350000 },
  { label: '400.000', value: 400000 },
  { label: '550.000', value: 450000 },
  { label: '500.000', value: 500000 },
  { label: '600.000', value: 600000 },
  { label: '600.000 e oltre', value: 50000000 },
];

const safeParseJson = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return {};
  }
};

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @select() public properties!: Promise<any[]>;
  @select(['presetUrls', 'landingPage']) public landingPage: any;
  @select(['presetUrls', 'landingPageAreas']) public landingPageAreas: any;
  @select(['config', 'noMatchFound']) public noMatchFound!: Observable<boolean>;
  @select(['location', 'citiesAndAreas'])
  public citiesAndAreas!: Observable<ICityArea>;

  public searchFields: SearchFields = {};
  public busy = false;

  public prezzoMin!: number;
  public prezzoMax!: number;
  public tipologia!: number;
  public categoria!: number;

  public priceValues: {
    label: string;
    value: number | null;
  }[] = priceValues;

  public propertyTypes: PropertyTypes['data'] = [];
  public propertyCategories: PropertyCategories['data'] = [];

  private immobiliSubscription!: Subscription;

  public autoUnsubscribe = new AutoUnsubscribe();

  public catTx = '';

  constructor(
    private service: DataService,
    protected store: NgRedux<IAppState>,
    private activatedRoute: ActivatedRoute,
    private meta: MetaService,
    private router: Router,
    private scrollService: ScrollToService
  ) {
    this.checkLocationClient();
    this.getPropertyCategories();
    this.getPropertyTypes();
  }

  ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  ngOnInit() {
    console.log('onInit');

    const valueProp = 'value';
    let isLandingPage = (this.activatedRoute.url as any)[valueProp].length > 1; // TODO
    const ctas = StaticContentPipe.getContent('HOME.BOX.CTAS');
    let catTx = '';

    const subscription = this.activatedRoute.params.subscribe((params: any) => {
      // todo move to config
      if (params.categoryPage === 'categoria') {
        const cat = ctas.find((c: any) => c.PATH === params.value);
        (this.searchFields as any)[`immobileCategoria|label`] = cat.PATH;
        catTx = `- ${cat.PATH}`;
        isLandingPage = false;
      }

      // todo move to config
      if (params.categoryPage === 'comune') {
        this.searchFields.city = params.value;
        isLandingPage = false;
      }
    });

    this.autoUnsubscribe.add(subscription);

    const subscription2 = this.activatedRoute.queryParams.subscribe(
      (params) => {
        const refIdProp = 'refId';
        this.searchFields.rifAnnuncio = params[refIdProp];
      }
    );

    this.autoUnsubscribe.add(subscription2);

    if (!isLandingPage) {
      this.setMeta(
        StaticContentPipe.getContent(
          'SHARED.PROPERTY_SEGMENT.DEFAULT_META_TITLE'
        ) + catTx,
        StaticContentPipe.getContent(
          'SHARED.PROPERTY_SEGMENT.DEFAULT_META_DESCRIPTION'
        ) + catTx
      );
    }

    return isLandingPage
      ? this.getImmobiliServerWithUrl()
      : this.getImmobiliServer(false);
  }

  @server
  private getImmobiliServerWithUrl() {
    const valueProp = 'value';
    const seoUrl = (this.activatedRoute.url as any)[valueProp][1]; // TODO:

    let subscr2;

    const subscription = this.service.getPresetUrl(seoUrl).subscribe((data) => {
      const landingPage = data.data[0]; // todo !!!

      if (typeof landingPage === 'undefined') {
        this.router.navigate(['/404']);
      }

      this.store.dispatch(PresetUrlActions.setLandingPage(landingPage));

      const landingPageFound = typeof landingPage !== 'undefined';

      if (landingPageFound) {
        this.setMeta(landingPage.metaTitle, landingPage.metaDescription);
      }

      this.searchFields = landingPageFound
        ? safeParseJson(landingPage.data)
        : {};

      console.log(this.searchFields, { landingPageFound, landingPage });

      // #################### quartieri #############################

      const city = this.searchFields.city;

      console.log({ city });

      subscr2 = this.service.getAreas(city).subscribe(
        (d) => {
          console.log({ areas: d });
          this.store.dispatch(
            PresetUrlActions.setLandingPageAreas(
              d.data.filter((area: string) => area !== null)
            )
          );
        },
        (err) => {
          console.log('E', err);
        }
      );

      this.autoUnsubscribe.add(subscr2);

      // #################################################

      return this.getImmobiliServer(true);
    });

    this.autoUnsubscribe.add(subscription);
  }

  @server
  private getImmobiliServer(numericFilterMap: boolean) {
    const citiesAreasSubscription = this.getCitiesAndGroupedAreas();

    const dataMap = !numericFilterMap
      ? searchToPropertyMap
      : Object.keys(searchToPropertyMap).reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: searchToPropertyMap[cur].split(':id').join(':label'),
          }),
          {}
        );

    let params = this.computeParams(this.searchFields, dataMap);

    params = {
      ...params,
      'assets|sortOrder': 0, // TODO:
    };

    this.immobiliSubscription = this.service
      .getImmobili(params)
      .subscribe((data) => {
        this.store.dispatch(PropertiesActions.setProperties(data.data));
      });

    return forkJoin([citiesAreasSubscription, this.immobiliSubscription]); // pipe ?
  }

  @server
  public getCitiesAndGroupedAreas(): Subscription {
    // todo use an array in place of an obj (sorting issues)

    const subscription = this.service
      .getTwoHierarchicalGroupFields('city', 'area')
      .subscribe(
        (d) => {
          const init: ICityArea[] = [];

          const primaryCity = StaticContentPipe.getContent(
            'SHARED.PROPERTY_SEGMENT.PRIMARY_CITY'
          );

          let CityArea: ICityArea[] = Object.keys(d.data).reduce((acc, cur) => {
            if (cur !== '') {
              const obj: ICityArea = {
                city: cur,
                area: d.data[cur],
              };
              return [...acc, obj];
            }
            return acc;
          }, init);

          const primaryPos = CityArea.findIndex((c) => c.city === primaryCity);

          if (primaryPos) {
            CityArea = [...CityArea.splice(primaryPos, 1), ...CityArea];
          }

          this.store.dispatch(
            LocationActions.setLocationCitiesAndAreas(CityArea)
          );
        },
        (err) => {
          console.log(err);
        }
      );

    this.autoUnsubscribe.add(subscription);

    return subscription;
  }

  @client
  public search(scroll: boolean = false): void {
    this.busy = true;

    this.searchFields.prezzo =
      (this.prezzoMin || 0) + '..' + (this.prezzoMax || 999999999);

    let params = {
      ...this.computeParams(this.searchFields, searchToPropertyMap),
      // 'assets:sortOrder': 0,
    };

    // todo: flatten fn
    // const flatten = arr =>
    //   arr.reduce(
    //     (acc, next) => acc.concat(Array.isArray(next) ? flatten(next) : next),
    //     []
    //   );

    const likeOperatorFields = ['city'];

    params = Object.keys(params).reduce((acc: any, k: string, i: number) => {
      const key = likeOperatorFields.indexOf(k) > -1 ? `${k}~` : k;
      return { ...acc, [key]: params[k] };
    }, {});

    const subscription = this.service.getImmobili(params).subscribe(
      (data) => {
        this.store.dispatch(PropertiesActions.setProperties(data.data));
        this.busy = false;

        if (scroll) {
          this.scrollService.scrollTo({
            target: 'search-results',
          });
        }
      },
      (err) => {
        console.log(err);
        this.busy = false;
      }
    );

    this.autoUnsubscribe.add(subscription);
  }

  @client
  public async getPropertyTypes() {
    const data = await this.service.getPropertyTypes();
    this.propertyTypes = data.data;
  }

  @client
  public async getPropertyCategories() {
    const data = await this.service.getPropertyCategories();
    this.propertyCategories = data.data;
  }

  public computeParams(searchData: any, dataMap: ISimpleMap): any {
    Object.keys(searchData).forEach((k) => {
      if (searchData[k] && k.indexOf('|label') > -1) {
        searchData[k] = searchData[k].replace('-', ' ');
      }
    });

    // searchData = Object.keys(searchData).reduce(
    //   (acc: any, cur: string) => ({
    //     ...acc,
    //     [cur]: searchData[cur]
    //       ? searchData[cur].indexOf('|label') > -1
    //         ? searchData[cur].replace('-', ' ')
    //         : searchData[cur]
    //       : null
    //   }),
    //   {}
    // );

    return Obj.unsetEmpty(Obj.mapKeys<any, any>(searchData || {}, dataMap));
  }

  @client
  public checkLocationClient() {
    const subscription = this.noMatchFound.subscribe((v) => {
      if (v === true) {
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    });

    this.autoUnsubscribe.add(subscription);
  }

  public cityWithArticle(city: string): string {
    if (!city) {
      return '';
    }

    const vowels = ['a', 'e', 'i', 'o', 'u'];

    const preposition = vowels.indexOf(city[0].toLowerCase()) > -1 ? 'ad' : 'a'; // todo use getPreposition

    return `${preposition} ${city}`;
  }

  public areasText(areas: string[] | null): string {
    return areas
      ? areas.map((area: string) => HbsHelpers.capitalize(area)).join(', ')
      : '';
  }

  public zoneText(areas: string[] | null): string {
    if (typeof areas === 'undefined' || areas === null) {
      return '';
    }

    return areas.length > 1 ? 'nelle zone' : 'in zona';
  }

  public getPreposition(word: string): string {
    return ['a', 'e', 'i', 'o', 'u'].indexOf(word[0].toLowerCase()) > -1
      ? 'ad'
      : 'a';
  }

  public txToHref(tx: string): string {
    return tx.toLowerCase().split(' ').join('-');
  }

  public setMeta(title: string, description: string): void {
    const metaObj: IPageMeta = {
      author: StaticContentPipe.getEnv('paths.baseUrl'),
      title,
      description,
      siteName: StaticContentPipe.getEnv('paths.baseUrl'),
      image:
        StaticContentPipe.getEnv('paths.baseUrl') +
        '/assets/logo/simco-logo-seo.png',
      url: StaticContentPipe.getEnv('paths.baseUrl'),
    };

    this.meta.setMeta(metaObj);
  }

  public urlContatti() {
    const staticContent = new StaticContentPipe();
    const staticRoutes = staticContent.transform('SHARED.HEADER.MENU');
    const contattiItem = staticRoutes.find(
      (item: any) => item.name === 'Contatti'
    );

    return contattiItem.url;
  }
}
