<article class="sc-articles-listwrp">
  <h3 class="sc-articles-list__title">{{ 'ARTICLE_LIST.TITLE' | staticContent }}</h3>

  <h4 class="sc-articles-list__title">{{ 'ARTICLE_LIST.SUBTITLE' | staticContent }}</h4>



  <div class="sc-articles-list">
    <div
      class="sc-articles-list__arrow__icons__container"
      (swipeLeft)="swapAhead()"
      (swipeRight)="swapBackwards()"
      data-mc-options='{ "touchAction": "pan-y" }'
    >
      <div class="sc-articles-list__arrow__cycle-left" (click)="swapBackwards()"></div>
      <div class="sc-articles-list__arrow__cycle-right" (click)="swapAhead()"></div>
    </div>

    <a
      [href]="'/news/' + article?.seoUrl"a
      class="sc-articles-list-item"
      *ngFor="let article of articles | async"
      [appNgAnalyticsSendEvent]="[
        'Click',
        'property[route=' + router.url + '].article.' + article.id,
        '.refId: ' + article?.id + ', seoUrl: ' + article?.seoUrl + ', page: ' + router?.url
      ]"
    >
      <mat-card class="sc-articles-list-item__card">
        <img
          appLazy
          class="sc-articles-list-item__card__image lazy"
          [src]="getImgPath(article.coverImage, 'w450')"
          [alt]="article.coverImageAlt"
          [title]="article.coverImageTitle"
          [srcset]="getSrcSet({ url: article.coverImage, loaded: true, opacity: 1, zIndex: 0 }, [450])"
        />
        <div class="sc-articles-list-item__card__text">
          <h3>{{ article.title }}</h3>
          <h4>{{ article.subtitle }}</h4>
        </div>
      </mat-card>
    </a>
  </div>
</article>
