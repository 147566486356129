import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AssetLib } from '../../lib/assets.lib';
import { HttpClient } from '@angular/common/http';
import { client } from '../../decorator/client.decorator';
import { IAsset } from '../../interface/asset.interface';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { ConfigActions } from '../../module/store/config/config.action';
import { DataService } from '../../data.service';
import { Observable } from 'rxjs';

const $ = (a: any) => ({
  addClass: (_: any) => null,
  removeClass: (_: any) => null,
});

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ESC = 27,
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent extends AssetLib implements OnInit, OnChanges {
  // todo: add resize name as input

  @Input() public id!: number;

  @Input() public imgUrl!: string;

  @Input() public property: any;

  // @Input() public sold: boolean = false;

  @Input() public assets: any[] = [];

  @Input() public lazy = false;

  @Input() public resizedPresetName!: string;

  @Input() public detailsEnabled = true;

  @Input() public navArrowsEnabled = true;

  @Input() public navDotsEnabled = true;

  @Input() public previewMode = false;

  @select(['config', 'galleryFullScreen'])
  public readonly fullScreen!: Observable<boolean>;

  public galleryAssets: any[] = [];

  public currentIndex = 0;

  public assetWidthPresets = [1500, 800];

  private width!: number;

  public fsLabel = 'Ingrandisci la foto';

  // public lazyLoadAssets: true;
  // public lazyLoadAssetsLoaded = false; // use state
  //
  // public loadingAssets = false;
  //
  // public swapQueued = 0;

  public constructor(
    protected http: HttpClient,
    protected ref: ChangeDetectorRef,
    protected store: NgRedux<IAppState>,
    protected service: DataService
  ) {
    super();
  }

  public ngOnInit() {
    this.setInitialClientWidth();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.galleryAssets.length === 0) {
      // store state and keep it, reapply it on previous collection if existing

      const assetsPropName = 'assets';

      if (typeof changes[assetsPropName] !== 'undefined') {
        this.setGalleryAssets(changes[assetsPropName].currentValue);
      }
    }
  }

  public setGalleryAssets(assets: any[]) {
    this.galleryAssets = this.imgDataToAssetsData(assets || []);
    // todo merge based on name ?
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.swapAhead();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.swapBackwards();
    }

    if (
      this.store.getState().config?.galleryFullScreen &&
      event.keyCode === KEY_CODE.ESC
    ) {
      this.toggleFullScreen();
    }
  }

  @client
  public setInitialClientWidth() {
    this.width = window.innerWidth;
  }

  // public onArrowHover() {
  //   if (this.lazyLoadAssets || !this.lazyLoadAssetsLoaded) {
  //     this.loadingAssets = true;
  //     this.lazyLoadAssetsLoaded = true;
  //
  //     this
  //       .loadAssets()
  //       .subscribe(
  //         (assets: any) => {
  //           this.store.dispatch(PropertyActions.setPropertyAssets(assets.data));
  //           this.setGalleryAssets(assets.data);
  //
  //           if (this.swapQueued !== 0) {
  //             this.swap(this.swapQueued);
  //             this.swapQueued = 0;
  //           }
  //
  //           this.loadingAssets = false;
  //
  //           this.ref.detectChanges();
  //         });
  //   }
  // }

  public swapAhead() {
    this.swap(1);
  }

  public swapBackwards() {
    this.swap(-1);
  }

  // public step(offset: number): void {
  //
  //   if (this.lazyLoadAssets || !this.lazyLoadAssetsLoaded) {
  //     this
  //       .loadAssets()
  //       .subscribe(
  //         (data: any) => {
  //           this.swap(offset);
  //         });
  //   } else if (this.loadingAssets) {
  //     this.swapQueued = offset;
  //     this.galleryAssets[this.currentIndex].loading = false;
  //   } else {
  //     this.swap(offset);
  //   }
  // }

  public swap(offset: number): void {
    const ci = this.currentIndex;

    const ni =
      offset > 0
        ? this.currentIndex < this.galleryAssets.length - 1
          ? this.currentIndex + offset
          : 0
        : this.currentIndex !== 0 // todo: compute next iteration page in order to allow offsets != 1
        ? this.currentIndex + offset
        : this.galleryAssets.length - 1;

    const current: any = this.galleryAssets[ci];

    const next: any = this.galleryAssets[ni];

    if (Object.is(current, next)) {
      // { Object.is(current, next)
      return;
    }

    this.galleryAssets[ci].loading = true;
    this.ref.markForCheck();

    const temp = [...this.assetWidthPresets.sort((a, b) => a - b)];

    const resizePreset =
      temp.find((p: number) => {
        return p >= this.width;
      }) || temp[temp.length - 1];

    let im = new Image();

    im.src = this.getImgPath(this.galleryAssets[ni].url, `w${resizePreset}`);

    im.onload = () => {
      next.loaded = true;
      next.opacity = 1;
      current.opacity = 0;
      this.currentIndex = ni;
      current.loading = false;
      this.ref.markForCheck();
      im = null as any;
    };

    im.onerror = () => {
      next.loaded = true;
      next.opacity = 1;
      current.opacity = 0;
      this.currentIndex = ni;
      current.loading = false;
      this.ref.markForCheck();
      im = null as any;
    };
  }

  public moveToIndex(i: number) {
    this.galleryAssets = this.galleryAssets.map((asset: IAsset) => ({
      ...asset,
      opacity: 0,
      loaded: true,
    }));

    this.galleryAssets[i].opacity = 1;

    this.currentIndex = i;
  }

  public swipe(directionLToR: boolean = true): void {
    directionLToR ? this.swapAhead() : this.swapBackwards();
  }

  public getLoadedImgPath(asset: IAsset, resizedPresetName: string): string {
    return asset.loaded ? this.getImgPath(asset.url, resizedPresetName) : '';
  }

  public getSrcSet(asset: IAsset, sizes: number[]): string {
    return sizes
      .map((size: number) => {
        const path = this.getLoadedImgPath(asset, `w${size}`);

        return path !== '' ? `${path} ${size}w` : null;
      })
      .filter((item: any) => item !== null)
      .join(', ');
  }

  public isFs(i: number): boolean {
    return (
      (this.store.getState()?.config?.galleryFullScreen as boolean) &&
      this.currentIndex === i
    );
  }

  // public closest(goal: number, values: number[]): number {
  //   return values.reduce(function(prev, curr) {
  //     return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
  //   });
  // }

  @client
  public toggleFullScreen(): void {
    this.store.dispatch(ConfigActions.galleryToggleFullScreen());

    const fullScreen = this.store.getState().config?.galleryFullScreen;

    const logoSel = document.getElementsByClassName('sc-logo')[0];

    fullScreen
      ? logoSel.classList.add('sc-logo--fullscreen')
      : logoSel.classList.remove('sc-logo--fullscreen');

    // fullScreen
    //   ? $('.sc-logo').addClass('sc-logo--fullscreen')
    //   : $('.sc-logo').removeClass('sc-logo--fullscreen');

    // const bodySelector = $('html, body');

    const htmlSel = document.getElementsByTagName('html')[0];
    const bodySel = document.getElementsByTagName('body')[0];

    const className = 'no-scroll';

    if (fullScreen) {
      htmlSel.classList.add(className);
      bodySel.classList.add(className);
    } else {
      htmlSel.classList.remove(className);
      bodySel.classList.remove(className);
    }

    // fullScreen
    //   ? $('html, body').addClass('no-scroll')
    //   : $('html, body').removeClass('no-scroll');

    fullScreen
      ? (this.fsLabel = 'Riduci')
      : (this.fsLabel = 'Ingrandisci la foto');
  }

  public imgNameToAlt(text: string): string {
    return (
      text
        .split('.')[0]
        .split('-')
        // .splice(-1)
        .join(' ')
    );
  }

  // public loadAssets (lockUi: boolean = false): Observable<any> {
  //   // if (typeof this.id !== 'undefined') {} // handle error
  //
  //   if (lockUi) {
  //     this.galleryAssets[this.currentIndex].loading = true;
  //   }
  //
  //   return this.service
  //     .getAssets(this.id)
  //     .map(
  //       (assets) => {
  //         this.store.dispatch(PropertyActions.setPropertyAssets(assets.data));
  //         this.setGalleryAssets(assets.data);
  //
  //         this.ref.detectChanges();
  //
  //         this.lazyLoadAssetsLoaded = true;
  //
  //         // if (lockUi) {
  //         this.galleryAssets[this.currentIndex].loading = false;
  //         // }
  //
  //         return assets;
  //       }
  //     )
  //   ;
  // }
}
