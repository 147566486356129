import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsGaService } from './service/service/analytics-ga.service';
import { NgAnalyticsSendDirective } from './directive/ng-analytics-send.directive';

@NgModule({
  imports: [CommonModule],
  providers: [
    // AnalyticsGaService,
  ],
  exports: [
    // AnalyticsGaService,
    NgAnalyticsSendDirective,
  ],
  declarations: [
    // AnalyticsGaService,
    NgAnalyticsSendDirective,
  ],
})
export class NgAnalyticsModule {}
