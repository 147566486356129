import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { server } from '../../decorator/server.decorator';
import { ArticleActions } from '../../module/store/article/article.action';
import { DataService } from '../../data.service';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { Observable } from 'rxjs';
import { IArticle } from '../../interface/article.interface';
import { AssetLib } from '../../lib/assets.lib';
import { Router } from '@angular/router';
import { client } from '../../decorator/client.decorator';
import { StaticContentPipe } from 'src/app/pipe/static-content.pipe';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesListComponent extends AssetLib implements OnInit {
  @select(['article', 'articles']) public articles!: Observable<IArticle[]>;

  public limit = 4;
  public page = 0;
  public pageSize = null;

  public autoUnsubscribe = new AutoUnsubscribe();

  constructor(
    private service: DataService,
    protected store: NgRedux<IAppState>,
    public router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getArticles(this.limit, this.page, this.pageSize as unknown as number);
  }

  public swapAhead() {
    this.page += 1;
    this.getArticlesClient(
      this.limit,
      this.page,
      this.pageSize as unknown as number
    );
  }

  public swapBackwards() {
    this.page -= 1;
    if (this.page < 0) {
      this.page = 0;
    }

    this.getArticlesClient(
      this.limit,
      this.page,
      this.pageSize as unknown as number
    );
  }

  @server
  public getArticles(limit: number, page: number, pageSize: number) {
    const subscription = this.service
      .getArticles(limit, page, pageSize)
      .subscribe(
        (data: any) => {
          const articles = data.data;

          this.store.dispatch(ArticleActions.setArticles(articles));
        },
        (err) => console.log(err)
      );

    this.autoUnsubscribe.add(subscription);
  }

  @client
  public getArticlesClient(limit: number, page: number, pageSize: number) {
    const subscription = this.service
      .getArticles(limit, page, pageSize)
      .subscribe(
        (data: any) => {
          const articles = data.data;

          articles.length === 0 && this.page > 0
            ? this.getArticlesClient(
                this.limit,
                (this.page = 0),
                this.pageSize as unknown as number
              )
            : this.store.dispatch(ArticleActions.setArticles(articles));
        },
        (err) => console.log(err)
      );

    this.autoUnsubscribe.add(subscription);
  }
}
