import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucFirst',
})
export class UcFirstPipe implements PipeTransform {
  transform(text: string) {
    if (!text) {
      text = '';
    }

    return text.charAt(0).toUpperCase() + text.substr(1);
  }
}
