import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaticContentPipe } from '../../pipe/static-content.pipe';

export interface ISocial {
  class: string;
  name: string;
  url: string;
}

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent implements OnInit {
  public href!: string;

  public socials: ISocial[] = [
    {
      name: 'facebook',
      url: 'http://www.facebook.com/sharer.php?u=',
      class: 'fa-facebook-square',
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/intent/tweet?url=',
      class: 'fa-twitter-square',
    },
    {
      name: 'pinterest',
      url: 'https://pinterest.com/pin/create/link/?url=',
      class: 'fa-pinterest-square',
    },
  ];

  constructor(protected router: Router) {}

  ngOnInit() {
    this.href = StaticContentPipe.getEnv('paths.baseUrl') + this.router.url;
  }
}
