import { ISimpleMap } from '../interface/simple-map.interface';

const searchToPropertyMap: ISimpleMap = {
  city: 'city',
  categoria: 'immobileCategoria', // fixme: move to yml env conf
  tipologia: 'immobileTipologia', // fixme: move to yml env conf
  rifAnnuncio: 'refId',
  prezzo: 'price',
};

export { searchToPropertyMap };
