<div *ngIf="!cookieAccepted" id="cookieBanner">
  <p>
    {{ 'SHARED.HEADER.AGENCY_NAME' | staticContent }} fa uso di cookie per raccogliere informazioni sull’utilizzo del sito stesso.
    Utilizziamo cookie tecnici per inviare messaggi promozionali agli utenti.
    Può conoscere i dettagli in questo <a target="_blank" href="{{ 'SHARED.FOOTER.PRIVACY_POLICY_URL' | staticContent }}">link</a>.
    Proseguendo la navigazione si accetta l’uso dei cookie
  </p>

  <a
    href=""
    class="sc-button"
    mat-raised-button
    color="warn"
    (click)="accetto($event)">ACCETTO
  </a>

</div>


