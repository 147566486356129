<div class="sc-property-segment">
  <h2>{{ ('SHARED.PROPERTY_SEGMENT.TITLE' | staticContent )}}</h2>
  <div class="sc-property-segment__previewbox" >
    <ng-container
      *ngFor="let immobile of (immobili | async); let i = index;"
    >
      <div
        app-preview
        class="sc-preview"
        *ngIf="immobile.active && immobile.assets[0]"
        [property]="immobile"
        [index]="i"
      ></div>
    </ng-container>
  </div>
</div>
=