import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AutoUnsubscribe {
  public subscribers: Subscription[] = [];

  public add(sub: Subscription) {
    this.subscribers.push(sub);
  }

  /**
   * iterate through all the subscriptions previously marked
   * for destruction and unsubscribe these if defined
   */

  public onDestroy() {
    this.subscribers.forEach((s: Subscription) => {
      if (typeof s !== undefined && s !== null) {
        s.unsubscribe();
      }
    });
  }
}
