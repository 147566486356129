import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addHref',
})
export class AddHrefPipe implements PipeTransform {
  transform(value: string, href: string) {
    return href ? `<a href="${href}>${value}</a>` : value;
  }
}
