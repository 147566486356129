<a
  class="sc-preview__element"
  [href]="domainPath() + property?.seoUrl"
  [appNgAnalyticsSendEvent]="[
    'Click',
    'property[route=' + route?.routeConfig.path + '].preview.' + index,
    '.refId: ' +
      property?.refId +
      ', seoUrl: ' +
      property?.seoUrl +
      ', page: ' +
      router?.url
  ]"
>
  <article>
    <mat-card class="sc-preview__card">
      <section class="sc-preview__image">
        <app-gallery
          [imgUrl]="getFirstAsset(property.assets)?.fileName"
          [property]="property"
          [resizedPresetName]="'w450'"
          [navArrowsEnabled]="false"
          [navDotsEnabled]="false"
          [previewMode]="true"
        ></app-gallery>
      </section>
      <mat-card-content>
        <section class="sc-preview__description">
          <h3
            [ngClass]="
              property.sold
                ? 'sc-preview__description__name--greyscale'
                : 'sc-preview__description__name'
            "
          >
            {{ property?.metaTitle | ucFirst }}
          </h3>
          <div class="sc-preview__description__details">
            <div class="sc-preview__description__details__left">
              <span *ngIf="!property?.priceReserved">
                <i class="icon-icons_euro_symbol"></i>
                {{ property?.price | currencyFormat }}
              </span>
              <span *ngIf="property?.priceReserved">
                <!--<i class="icon-icons_euro_symbol"></i>-->
                <!-- TRATTATIVA RISERVATA -->
                <!-- Trattativa riservata -->
              </span>
              <span>
                <i class="icon-icons_mq"></i>
                {{ property?.surfaceSqm }} mq
              </span>
            </div>

            <div
              [ngClass]="
                property.sold
                  ? 'sc-preview__description__details__right__info--greyscale'
                  : 'sc-preview__description__details__right__info'
              "
            >
              <span>{{ property?.city | ucFirst }}</span>
              <span>{{ getArea() | ucFirst }}</span>
            </div>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
  </article>
</a>
