import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IProperty } from '../../interface/property.interface';
import { DataService } from '../../data.service';
import { PropertiesActions } from '../../module/store/properties/properties.actions';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { server } from '../../decorator/server.decorator';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';

@Component({
  selector: 'app-property-segment',
  templateUrl: './property-segment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySegmentComponent implements OnInit, OnDestroy {
  @select(['properties']) public immobili!: Promise<IProperty[]>;

  // Param to customize segment
  @Input() public key!: string;
  @Input() public value!: string;
  public autoUnsubscribe = new AutoUnsubscribe();

  constructor(
    private service: DataService,
    protected store: NgRedux<IAppState>
  ) {}

  ngOnInit() {
    this.fetchDataServer();
  }

  ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  @server
  fetchDataServer() {
    const subscription = this.service
      .getPropertySegment(this.key, this.value)
      .subscribe(
        (data: any) => {
          this.store.dispatch(PropertiesActions.setProperties(data.data));
        },
        (err: any) => {
          console.log(err);
        }
      );

    this.autoUnsubscribe.add(subscription);
  }
}
