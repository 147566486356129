import { Reducer } from 'redux';

const initialState = {
  article: null,
  articles: [],
};

export const articleReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_GLOBAL':
      return {
        ...action.payload,
      };

    case 'SET_ARTICLE':
      return {
        ...state,
        article: action.payload,
      };

    case 'SET_ARTICLES':
      return {
        ...state,
        articles: [...action.payload],
      };
  }

  return state;
};
