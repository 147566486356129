export function client(
  // tslint:disable-next-line
  target: any & Object,
  key: string | symbol,
  descriptor: PropertyDescriptor
) {
  const original = descriptor.value;

  descriptor.value = function (...args: any[]) {
    if (typeof window !== 'undefined') {
      const result = original.apply(this, args);
      return result;
    } else {
      return null;
    }
  };

  return descriptor;
}
