import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';
import { ICityArea } from '../../../interface/city-areas.interface';

@Injectable()
export class LocationActions {
  static SET_LOCATION = 'SET_LOCATION';
  static SET_LOCATION_CITIES_AND_AREAS = 'SET_LOCATION_CITIES_AND_AREAS';

  static setLocation(location: any): AnyAction {
    return {
      type: LocationActions.SET_LOCATION,
      payload: location,
    };
  }

  static setLocationCitiesAndAreas(citiesAndAreas: ICityArea[]): AnyAction {
    return {
      type: LocationActions.SET_LOCATION_CITIES_AND_AREAS,
      payload: citiesAndAreas,
    };
  }
}
