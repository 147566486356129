import { Reducer } from 'redux';

const initialState = null;

export const clientReducer: Reducer<any> = (
  state = initialState,
  action: any
): any[] => {
  switch (action.type) {
    case 'SET_HOJTAR':
      return {
        ...state,
        hotjar: action.payload,
      };
    case 'SET':
      return {
        ...action.payload,
      };
  }

  return state;
};
