import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { env } from '../lib/config.lib';

const debugHttp: boolean = env.debug.http;

@Injectable()
export class HttpDebugInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: any) => {
          // todo: add random id
          if (debugHttp) {
            const data = {
              req,
              event,
            };

            if (event.type !== 0) {
              console.log('#success', data);
            }
          }
        },

        (error: any) => {
          if (debugHttp) {
            const data = {
              req,
              error,
            };

            console.log('#err', data);
          }
        }
      )
    );
  }
}
