<section class="sc-contact">
  <div class="sc-contact__form">
    <div class="sc-contact__actions__close">
      <button mat-icon-button>
        <mat-icon
          (click)="formVisible(false)"
          [appNgAnalyticsSendEvent]="['Click', 'property.form.contact.close']"
        >close</mat-icon>
      </button>
    </div>

    <mat-card-content>
      <div class="my-2">
        Per avere informazioni sull’appartamento o confermare una prima visita completa questo form, ti ricontatterò in giornata.
      </div>

      <form #f="ngForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">

        <mat-form-field>
          <input
            matInput
            placeholder="Nome *"
            value=""
            formControlName="firstName"
          >
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Cognome *"
            value=""
            formControlName="lastName"
          >
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Telefono *"
            value=""
            formControlName="phone"
          >
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            value=""
            formControlName="email"
          >
        </mat-form-field>

        <mat-form-field class="sc-contact__field__date">
          <input
            matInput
            placeholder="Data preferibile per appuntamento."
            formControlName="data"
            [matDatepicker]="picker"
            style="width: 100%"
          >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>

        </mat-form-field>

        <mat-checkbox
          placeholder="privacy"
          formControlName="privacy"
          style="width: 100%"
        >
          Ho letto e accetto il <a href="" target="_blank">documento sulla privacy</a>
        </mat-checkbox>

        <app-recaptcha [sitekey]="'6LeihmwUAAAAABjQOx37lHhyL-g-uL5ThF-P4kJS'" formControlName="recaptcha"></app-recaptcha>

        <div class="sc-contact__bt--submit text-center">
          <button
            type="submit"
            class="btn sc-button my-2"
            [disabled]="!submitEnabled()"
            mat-raised-button
            color="warn"
            [appNgAnalyticsSendEvent]="['Click', 'property.form.contact.submit']"
          >
            Invia la tua richiesta
          </button>
        </div>
      </form>

      <div *ngIf="isMobile || true" class="text-center mt-3 my-2 text-center">

        <div class="sc-contact__divider">
          <strong class="sc-contact__divider--title">oppure</strong>
        </div>

        <a
          class="btn sc-button mat-whatsapp mt-2"
          type="button"
          mat-raised-button
          [href]="whatsAppHref()"
          target="_blank"
          [appNgAnalyticsSendEvent]="['Click', 'property.form.contact.whatsappBt']"
        >
          <i class="fa fa-2x fa-whatsapp mr-2"></i>
          <span>Contattami su Whatsapp</span>
        </a>
      </div>

    </mat-card-content>

  </div>
</section>
<!--<section class="sc-contact__greeting" [hidden]="!sent">-->
  <!--Grazie per averci contattato, ti faremo sapere al più presto.-->
<!--</section>-->
