import { Reducer } from 'redux';

const initialState = {
  header: [],
  footer: [],
};

export const infoReducer: Reducer<any> = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case 'SET_INFO':
      return {
        ...action.payload,
      };

    case 'SET_HEADER':
      return {
        ...state,
        header: [...action.payload],
      };

    case 'SET_FOOTER':
      return {
        ...state,
        footer: [...action.payload],
      };

    case 'SET_REVIEWS':
      return {
        ...state,
        reviews: [...action.payload],
      };
  }
  return state;
};
