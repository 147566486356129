<div>
  <div class="personal-message">
    <div class="personal-message__avatar">
      <img width="100" [src]="'SHARED.PROPERTY_SEGMENT.CONTACT_AVATAR' | staticContent" />
    </div>
    <div class="personal-message__body">
      <span [innerHTML]="data.message | safeHtml"></span>
    </div>

    <div class="personal-message__btn--submit__cnt">
      <button
        class="personal-message__btn--submit"
        (click)="close()"
        type="submit"
        class="btn sc-button my-2"
        mat-raised-button
        color="warn"
      >
        {{ data.bt }}
      </button>
    </div>
  </div>
</div>
