import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { IPageMeta } from './interface/pagemeta';
import { HbsHelpers } from '../amp/helpers.hbs';

type Tag = { name?: string; content: string; property?: string };
@Injectable()
export class MetaService {
  constructor(private meta: Meta, private title: Title) {}

  public setMeta(data: IPageMeta) {
    data.title = HbsHelpers.ucFirst(data.title);
    data.description = HbsHelpers.ucFirst(data.description);

    const tags: Tag[] = [
      { name: 'author', content: data.title },
      // { name: 'keywords', content: data.keywords},
      { name: 'description', content: data.description },
      { property: 'og:site_name', content: data.siteName },
      { property: 'og:title', content: data.title },
      { property: 'og:description', content: data.description },
      { property: 'og:image', content: data.image },
      { property: 'og:url', content: data.url },
      { property: 'og:type', content: 'website' },
    ];

    if (typeof data.amphtml !== 'undefined' && data.amphtml !== null) {
      tags.push({ property: 'amphtml', content: data.amphtml });
    }

    this.meta.addTags(tags);
    this.title.setTitle(data.title);
  }

  public setMetaObj(data: IPageMeta) {
    const tags = Object.keys(data) // { name: string; content: string; }[/
      .map((key) => ({
        name: key,
        content: (data as any)[key],
      }));
    this.meta.addTags(tags);
  }
}
