import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { DataService } from '../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { client } from '../../decorator/client.decorator';
import { ISimpleMap } from '../../interface/simple-map.interface';
import { Obj } from '../../lib/obj.lib';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AutoUnsubscribe } from '../../lib/auto-unsubscribe.lib';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { StaticContentPipe } from '../../pipe/static-content.pipe';

@Component({
  selector: 'app-contact-form-inline',
  templateUrl: './contact-form-inline.component.html',
})
export class ContactFormInlineComponent
  extends ContactFormComponent
  implements OnInit, OnDestroy
{
  public profileForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl(''),
    fax: new UntypedFormControl(''), // hp
    middleName: new UntypedFormControl(''), // hp
    email: new UntypedFormControl('', {
      validators: [Validators.required, Validators.email],
    }),

    ...(StaticContentPipe.getFeatures(
      'pages.property.inline_contact_form.privacy_checkbox'
    )
      ? { privacy: new UntypedFormControl('', Validators.required) }
      : {}),
  });

  public submitted = false;
  public autoUnsubscribe = new AutoUnsubscribe();
  public sent = false;
  public isMobile = false;

  constructor(
    public service: DataService,
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    super(service, changeDetectorRef, dialog);
  }
  public ngOnInit() {
    this.checkAgent();
  }

  public ngOnDestroy() {
    this.autoUnsubscribe.onDestroy();
  }

  public submitEnabled = () => this.profileForm.valid;

  public openSuccessDialog = (): void => {
    const staticContent = new StaticContentPipe();
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '585px',
      data: {
        message: staticContent.transform(
          'SHARED.PROPERTY_SEGMENT.CONTACT_FORM_GREETING'
        ),
        bt: 'OK',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.formVisible(false);
      this.changeDetectorRef.markForCheck();
    });
  };

  public whatsAppHref = () => {
    const staticContent = new StaticContentPipe();
    const phoneNumber = staticContent.transform(
      'PROPERTY.CTA_CONTATTACI.PHONE'
    );
    const dominio = staticContent.transform('SHARED.HEADER.DOMAIN');
    const seoUrl = `https://${dominio}/immobili-in-vendita-a-savona/${this.propertySeoUrl}?web`;

    // todo: move in templates
    const text = `Ciao,\nsono interessato alla proprietà con riferimento ${this.propertyRef}.\n${seoUrl}`;

    const api = this.isMobile ? 'api' : 'web';
    const url = `https://${api}.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;

    return encodeURI(url);
  };

  @client
  public checkAgent() {
    this.isMobile =
      navigator.userAgent.match(/Android|iPhone/i) &&
      !navigator.userAgent.match(/iPod|iPad/i)
        ? true
        : false;
  }

  // private onFormChanges = (data: any): void => {
  //   Object
  //     .keys(this.profileForm.controls)
  //     .forEach((controlName: string) => {
  //       const control = this.profileForm.controls[controlName];
  //       control.updateValueAndValidity({ onlySelf: true});
  //     });
  //   this.profileForm.updateValueAndValidity({
  //     onlySelf: true,
  //     emitEvent: false,
  //   });
  // this.changeDetectorRef.markForCheck();
  // this.changeDetectorRef.detectChanges();
  // }

  public onSubmit() {
    if (this.submitEnabled()) {
      this.submitted = true;

      const formToAppointmentMap: ISimpleMap = {
        name: 'name',
        email: 'email',
        message: 'date',
        ...(StaticContentPipe.getFeatures(
          'pages.property.inline_contact_form.privacy_checkbox'
        )
          ? { privacy: 'privacyAccepted' }
          : {}),
      };

      const payload = {
        ...(Obj.mapKeys(
          this.profileForm.value,
          formToAppointmentMap
        ) as Object),
        propertyId: this.propertyId,
      };

      const subscription = this.service
        .postSimpleContactForm(payload)
        .subscribe((_) => {
          this.openSuccessDialog();
          this.changeDetectorRef.markForCheck();
        });

      this.autoUnsubscribe.add(subscription);
    }
  }

  public formVisible(visible: boolean): void {
    this.formIsVisibleEmitter.emit(visible);
  }

  public toggle(key: string) {
    return StaticContentPipe.getFeatures(key);
  }
}
