<div class="sc-search">
  <div class="sc-search__landingPageBar" *ngIf="landingPage | async">
    <h1>{{ (landingPage | async)?.hrefText }}</h1>
  </div>

  <div class="sc-search__landingPageBar__h2" *ngIf="landingPage | async">
    <!--<pre>{{landingPage | async | json}}</pre>-->
    <h2>
      <!-- todo: yml !!! -->
      In questa sezione puoi trovare tutti gli appartamenti, ville o nuove
      costruzioni in vendita
      {{ cityWithArticle((landingPage | async)?.data.city) }}
      {{ zoneText(landingPageAreas | async) }}
      {{ areasText(landingPageAreas | async) }} su
      <a href="/">{{ "SHARED.HEADER.DOMAIN" | staticContent }}</a
      >. Contattaci <a href="{{ urlContatti() }}">qui</a> per qualsiasi
      informazione o per confermare un primo appuntamento. Puoi contattarci via
      email, telefono o direttamente su whatsapp e risponderemo a tutte le tue
      domande.
    </h2>
  </div>

  <div *ngIf="!(landingPage | async)" class="sc-search__filters">
    <h1 class="sc-search__title">
      {{ "SEARCH.TITLE" | staticContent }}
      {{ catTx }}
    </h1>

    <h2 class="sc-search__subTitle">
      In questa sezione puoi cercare tutti gli appartamenti, ville o nuove
      costruzioni in vendita
      <!--nelle zone:-->
      <span *ngFor="let city of citiesAndAreas | async">
        <ng-container *ngIf="city.area.length > 0">
          {{ getPreposition(city.city) }}
          <a
            class="sc-search__subTitle__a"
            [href]="'/case-in-vendita/' + txToHref(city.city) | lowercase"
            >{{ city.city | ucFirst }}</a
          >&nbsp;

          {{ zoneText(city.area) }}

          <span *ngFor="let area of city.area; let j = index">
            <span>{{ area | ucFirst }}</span>
            <span *ngIf="j !== city.area.length - 1">, </span>
          </span>
        </ng-container>
      </span>
      su <a href="/">{{ "SHARED.HEADER.DOMAIN" | staticContent }}</a
      >.
    </h2>

    <div *ngIf="busy" class="sc-search__busy">
      <div class="sc-search__busy__spinner-cnt">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>

    <form
      class="form-horizontal form-inline 2py-md-3 col"
      autocomplete="off"
      #searchForm="ngForm"
    >
      <mat-form-field class="rem-mx-1">
        <input
          matInput
          placeholder="Comune"
          value=""
          [(ngModel)]="searchFields.city"
          id="comune"
          name="comune"
          #city="ngModel"
        />
      </mat-form-field>

      <mat-form-field class="rem-mx-1">
        <mat-select
          placeholder="Categoria"
          id="categoria"
          name="categoria"
          [(value)]="searchFields.categoria"
        >
          <mat-option>-</mat-option>
          <mat-option
            *ngFor="let item of propertyCategories"
            [value]="item.id"
            >{{ item?.label || item?.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field class="rem-mx-1">
        <mat-select
          placeholder="Tipologia"
          id="tipologia"
          name="tipologia"
          [(value)]="searchFields.tipologia"
        >
          <mat-option>-</mat-option>
          <mat-option *ngFor="let item of propertyTypes" [value]="item.id">{{
            item?.label || item?.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="rem-mx-1">
        <mat-select
          placeholder="Prezzo min"
          id="prezzoMin"
          name="prezzoMin"
          [(ngModel)]="prezzoMin"
        >
          <mat-option *ngFor="let item of priceValues" [value]="item.value">{{
            item?.label || item?.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="rem-mx-1">
        <mat-select
          id="prezzoMax"
          name="prezzoMax"
          placeholder="Prezzo max"
          [(ngModel)]="prezzoMax"
        >
          <mat-option *ngFor="let item of priceValues" [value]="item.value">{{
            item?.label || item?.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="rem-mx-1">
        <input
          matInput
          placeholder="Riferimento"
          value=""
          [(ngModel)]="searchFields.rifAnnuncio"
          id="rifAnnuncio"
          name="rifAnnuncio"
          #rifAnnuncio="ngModel"
        />
      </mat-form-field>

      <button
        type="submit"
        class="btn sc-button mb-2 d-sm-none"
        [disabled]="!searchForm.form.valid"
        mat-raised-button
        color="warn"
        (click)="search(true)"
      >
        VAI<!-- todo: yml -->
      </button>

      <button
        type="submit"
        class="btn sc-button mb-2 d-none d-sm-block"
        [disabled]="!searchForm.form.valid"
        mat-raised-button
        color="warn"
        (click)="search()"
      >
        VAI<!-- todo: yml -->
      </button>
    </form>
  </div>
</div>

<div id="search-results" class="sc-search__results">
  <ng-container *ngFor="let property of properties | async; let i = index">
    <div
      app-preview
      class="sc-preview"
      *ngIf="property.active && property.assets.length > 0"
      [property]="property"
      [index]="i"
    ></div>
  </ng-container>

  <div
    *ngIf="
      (properties | async) &&
      (
        properties
        | async
        | collectionFilter: 'assets.length':'>':0
        | collectionFilter: 'active'
      )?.length === 0
    "
    class="sc-search__results__none"
  >
    <mat-icon>warning</mat-icon>
    <span>La ricerca non ha prodotto alcun risultato.</span>
  </div>
</div>
