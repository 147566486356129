import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class ConfigActions {
  static SET_CONFIG = 'SET_CONFIG';
  static SET_NOT_FOUND = 'SET_NOT_FOUND';
  static SET_NO_SCROLL = 'SET_NO_SCROLL';
  static GALLERY_TOGGLE_FULLSCREEN = 'GALLERY_TOGGLE_FULLSCREEN';

  static setConfig(config: any): AnyAction {
    return {
      type: ConfigActions.SET_CONFIG,
      payload: config,
    };
  }

  static setNotFound(): AnyAction {
    return {
      type: ConfigActions.SET_NOT_FOUND,
      payload: null,
    };
  }

  static setNoScroll(noScroll: boolean): AnyAction {
    return {
      type: ConfigActions.SET_NO_SCROLL,
      payload: noScroll,
    };
  }

  static galleryToggleFullScreen() {
    return {
      type: ConfigActions.GALLERY_TOGGLE_FULLSCREEN,
      payload: null,
    };
  }
}
