import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';

import { server } from '../../decorator/server.decorator';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../interface/app-state.interface';
import { ConfigActions } from '../../module/store/config/config.action';
// import { Request, Response } from 'express';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Optional() @Inject(RESPONSE) private response: any,
    protected store: NgRedux<IAppState>
  ) {}

  ngOnInit() {
    this.set404();
  }

  @server
  public set404() {
    this.store.dispatch(ConfigActions.setNotFound());

    this.response.status(404);
  }
}
