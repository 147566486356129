import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

@Injectable()
export class PageActions {
  static SET_PAGE = 'SET_PAGE';

  static setPage(page: any): AnyAction {
    return {
      type: PageActions.SET_PAGE,
      payload: page,
    };
  }
}
