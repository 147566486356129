import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { SearchComponent } from './component/search/search.component';
import { PropertyComponent } from './component/property/property.component';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { PagesComponent } from './component/pages/pages.component';
import { ArticleComponent } from './component/article/article.component';
import { SeocasaMatcher } from './route-matcher/seocasa.matcher';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },

  { matcher: SeocasaMatcher.searchMatcher, component: SearchComponent },
  { matcher: SeocasaMatcher.propertyMatcher, component: PropertyComponent },

  { path: 'case-in-vendita/:seoUrl', component: SearchComponent },
  { path: 'news/:seoUrl', component: ArticleComponent },
  { path: 'page/:seoUrl', component: PagesComponent },

  {
    matcher: SeocasaMatcher.toggleMatcher(
      'case-in-vendita/:seoUrl',
      'SearchComponent'
    ),
    component: SearchComponent,
  },
  {
    matcher: SeocasaMatcher.toggleMatcher('news/:seoUrl', 'ArticleComponent'),
    component: ArticleComponent,
  },
  {
    matcher: SeocasaMatcher.toggleMatcher('page/:seoUrl', 'PagesComponent'),
    component: PagesComponent,
  },

  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
