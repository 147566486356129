import jwtDecode from 'jwt-decode';
import { UserState } from '../module/store/user/user.reducers';

export const tokenToUser = (token: string): UserState | undefined => {
  let decodedToken: any;

  try {
    decodedToken = jwtDecode(token);
  } catch (e) {
    return undefined;
  }

  return {
    id: decodedToken.user.id as string,
    name: decodedToken.user.email as string,
    role: decodedToken.user.roles as string,
    token,
  };
};
